import { Box, Tab, Tabs, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPosition, setEditMode } from "../../toolkits/annotation/slice";
import {
  DEFAULT_ANNOTATION_TYPE_CAMERA,
  DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
  DEFAULT_ANNOTATION_TYPE_LIDAR,
  DEFAULT_ANNOTATION_TYPE_TANG,
  DEFAULT_EDIT_NONE,
  hasPosition,
} from "../../utils";
import { SelectCamera } from "./SelectCamera";
import { SelectHangRaoDienTu } from "./SelectHangRaoDienTu";
import { SelectLidar } from "./SelectLidar";
import { SelectTang } from "./SelectTang";

const DEFAULT_DIALOG_HEIGHT = 500;

const tabOptions = [
  {
    value: DEFAULT_ANNOTATION_TYPE_CAMERA,
    label: "Camera",
  },
  {
    value: DEFAULT_ANNOTATION_TYPE_LIDAR,
    label: "Lidar",
  },
  {
    value: DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
    label: "Hàng rào điện tử",
  },
  {
    value: DEFAULT_ANNOTATION_TYPE_TANG,
    label: "Cảnh báo tầng",
  },
];

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffffcc",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  color: "#ffffffcd",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#ffffff",
  },
  "&:hover": {
    color: "#ffffffef",
  },
}));

const DialogSelectAnnotation = () => {
  const dispatch = useDispatch();
  const { selectedPosition } = useSelector((state) => state.annotations);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(DEFAULT_ANNOTATION_TYPE_CAMERA);

  useEffect(() => {
    setOpen(hasPosition(selectedPosition));
  }, [selectedPosition]);

  const handleClose = () => {
    setOpen(false);
    dispatch(selectPosition());
    dispatch(setEditMode(DEFAULT_EDIT_NONE));
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#18403ccd",
          color: "#ffffffcc",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: DEFAULT_DIALOG_HEIGHT,
        }}
      >
        <Box>
          <CustomTabs
            value={tab}
            onChange={(e, tabValue) => setTab(tabValue)}
            centered
          >
            {tabOptions.map((tabOpt) => (
              <CustomTab
                key={tabOpt.value}
                label={tabOpt.label}
                value={tabOpt.value}
              />
            ))}
          </CustomTabs>
        </Box>

        <Box sx={{ flex: 1, overflowY: "auto" }}>
          {tab === DEFAULT_ANNOTATION_TYPE_CAMERA && (
            <SelectCamera setOpen={setOpen} />
          )}
          {tab === DEFAULT_ANNOTATION_TYPE_LIDAR && (
            <SelectLidar setOpen={setOpen} />
          )}
          {tab === DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU && (
            <SelectHangRaoDienTu setOpen={setOpen} />
          )}
          {tab === DEFAULT_ANNOTATION_TYPE_TANG && (
            <SelectTang setOpen={setOpen} />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogSelectAnnotation;
