import {
  Box,
} from "@mui/material";
import Home from "./Home";

const HomeScreen = ({ areaCode }) => {

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Home areaCode={areaCode} />
    </Box>
  );
};

export default HomeScreen;
