import { createSlice } from "@reduxjs/toolkit";

import { getLocalData } from "../../services/localStorage";

const initialState = {
  userData: {
    username: "",
    password: "",
  },
  isLoggedIn: getLocalData("isLoggedIn") ? getLocalData("isLoggedIn") : false,
  role: getLocalData("role") ? getLocalData("role") : "user",
  statusCode: 0,
  errorMassage: false,
};

const reducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
    },
    loginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.role = action.payload;
    },
    logout: (state, action) => {},
  },
});

export default reducer;
