import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    areaCode: "",
    selectedDevice: null,
    pageNumber: 0,
    pageSize: 50,
    devices: [],
    userAccess: [],
    userPresent: []
};

const reducer = createSlice({
    name: "devices",
    initialState,
    reducers: {
        getDevices: (state, action) => { },
        getDevicesByArea: (state, action) => {
            state.areaCode = action.payload;
        },
        setDevices: (state, action) => {
            state.devices = action.payload;
        },
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload
        },
        getUserByDevice: (state, action) => {

        },
        setUserbyDevice: (state, action) => {
            state.userAccess = action.payload;
        }
    },
});

export const { getDevicesByArea, getDevices } = reducer.actions

export default reducer;
