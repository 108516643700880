// import { useGLTF } from "@react-three/drei";
// import React from "react";

// export function BaseModel({ modelUrl, ...restProps }) {
//   const { scene } = useGLTF(modelUrl, "./draco" );
//   console.log(scene)
//   return <primitive object={scene}  {...restProps} />;
// }

// const MODEL_URL = "./glb/Mo_hinh_full_4.7.glb";

// export function Model(props) {
//   const { scene } = useGLTF(MODEL_URL);
//   // return <primitive object={scene} {...props} />;
//   console.log(scene);
//   return (
//     <group {...props} dispose={null} onClick={(e) => { e.stopPropagation(); console.log(e.camera.position); console.log(e.point) }}>
//       <BasicModel scenes={scene} />
//     </group>
//   );
// }

import { Button } from "@mui/material";
import { Box, Html } from "@react-three/drei";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box3, Color, NormalBlending, Vector3 } from "three";

import useActiveArea from "../../hooks/useActiveArea";
import useCheckModelEmergency from "../../hooks/useCheckModelEmergency";
import useSelectArea from "../../hooks/useSelectArea";
import eventSlice from "../../toolkits/event/slice";
import {
  DEFAULT_STATUS_COMPLETED_SKIP,
  DEFAULT_STATUS_COMPLETED_VIOLATION,
  DEFAULT_STATUS_PROCESSING,
  checkVisibleArea,
  checkVisibleFence,
  filterAreasInModel,
  fixScenceName,
  getStatusActionIcon,
  getStatusLabel,
  mappingArea2Model,
  mappingFences2Model,
} from "../../utils";
import DialogImage from "../DialogImage";

const BaseMesh = ({ item, controlRef, ...props }) => {
  return (
    <mesh
      ref={controlRef}
      name={item.name}
      geometry={item.geometry}
      material={item.material}
      // castShadow
      // receiveShadow
      // visible = {isFocus}
      // position={item.position}
      // rotation={item.rotation}
      // scale={item.scale}
      {...props}
    />
  );
};

const BaseGroup = ({
  group,
  blockBounds,
  isVisibleDetail,
  controlRef,
  ...props
}) => {
  const { pathname } = useLocation();

  const model = useMemo(
    () =>
      group.children?.map((scene) => {
        if (scene.children.length >= 1) {
          return <BaseGroup key={scene.name} group={scene} />;
        }
        return <BaseMesh key={scene.name} item={scene} />;
      }),
    [group.children]
  );

  const groupName = useMemo(
    () =>
      mappingArea2Model(group.name, pathname)?.name ??
      mappingFences2Model(group.name, pathname)?.name,
    [group.name, pathname]
  );

  return (
    <group
      ref={controlRef}
      position={group.position}
      {...props}
      // rotation={group.rotation}
      // scale={group.scale}
    >
      <meshStandardMaterial transparent opacity={0.5} />
      {isVisibleDetail ? (
        <Html position={blockBounds.min}>
          <div
            style={{
              color: "#ffff",
              backgroundColor: "rgba(0,0,0,.66)",
              justifyContent: "center",
              borderRadius: "6px",
              minWidth: 260,
              padding: "12px",
            }}
          >
            {groupName}
          </div>
        </Html>
      ) : null}
      {model}
    </group>
  );
};

const Item = ({ item }) => {
  return (
    // <Select enabled={hovered}>
    // <Select>
      <BaseMesh
        item={item}
        // controlRef={ref}
        // onPointerOver={() => hover(true)}
        // onPointerOut={() => hover(false)}
      />
    // </Select>
  );
};

const baseModelHoverColor = new Color("blue");
const baseModelSelectedColor = new Color("yellow");
const baseModelEmergencyColor = new Color("red");
const baseModelSuccessColor = new Color("green");
// const meshBasicMaterialBlue = new MeshBasicMaterial({
//   color: "red",
//   opacity: 1,
//   wireframeLinewidth: 10,
// });

const FilledBox = ({
  blockBounds,
  color = "black",
  opacity = "0.3",
  onClick,
}) => {
  const boundingBoxSize = useMemo(() => {
    const _boundingBoxSize = new Vector3();
    blockBounds.getSize(_boundingBoxSize);
    return _boundingBoxSize;
  }, [blockBounds]);

  const boundingBoxCenter = useMemo(() => {
    const _boundingBoxCenter = new Vector3();
    blockBounds.getCenter(_boundingBoxCenter);
    return _boundingBoxCenter;
  }, [blockBounds]);

  return (
    <mesh
      position={boundingBoxCenter.toArray()}
      renderOrder={1}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}
    >
      <boxGeometry args={boundingBoxSize.toArray()} />
      <meshBasicMaterial
        color={color}
        opacity={opacity}
        transparent
        blending={NormalBlending}
      />
    </mesh>
  );
};

const BlinkBox = ({
  blockBounds,
  enable,
  onClick,
  color = "red",
  outlineColor = baseModelEmergencyColor,
  opacity = 0.3,
  duration = 1000,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!enable) return;

    const interval = setInterval(() => setActive((v) => !v), duration);
    return () => {
      clearInterval(interval);
    };
  }, [enable, duration]);

  return enable ? (
    <>
      {active && <box3Helper args={[blockBounds, outlineColor]} />}
      {active && (
        <FilledBox
          blockBounds={blockBounds}
          color={color}
          opacity={opacity}
          onClick={onClick}
        />
      )}
    </>
  ) : null;
};

const FocusedBlinkBox = ({ sceneName, blockBounds, onClick }) => {
  const { pathname } = useLocation();
  const { focusedArea } = useSelector((state) => state.areas);
  const isModelFocused = useMemo(
    () =>
      Boolean(
        focusedArea?.areaCode &&
          filterAreasInModel(focusedArea?.areaCode).find(
            (it) => it.model === fixScenceName(sceneName, pathname)
          )
      ),
    [focusedArea, sceneName, pathname]
  );

  return isModelFocused ? (
    <BlinkBox
      blockBounds={blockBounds}
      enable={true}
      onClick={onClick}
      color="green"
      outlineColor={baseModelSuccessColor}
      opacity={0.7}
      duration={500}
    />
  ) : null;
};

const EVENT_ACTION_LIST = [
  DEFAULT_STATUS_PROCESSING,
  DEFAULT_STATUS_COMPLETED_SKIP,
  DEFAULT_STATUS_COMPLETED_VIOLATION,
];

const GroupMesh = ({ scenes }) => {
  const ref = useRef();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [isShowImage, setIsShowImage] = useState(false);
  const [hovered, hover] = useState(false);
  const { handleSelect } = useSelectArea();

  // check model has mapping
  const checkArea = useMemo(() => checkVisibleArea(scenes.name), [scenes.name]);
  const checkFence = useMemo(
    () => checkVisibleFence(scenes.name),
    [scenes.name]
  );
  const hasMappingModel = checkArea || checkFence;

  // check model selected
  const { areaModel } = useActiveArea();
  const isModelSelected = useMemo(
    () => areaModel === fixScenceName(scenes.name, pathname),
    [areaModel, scenes.name, pathname]
  );

  // block bound to render filled/blink box
  const blockBounds = useMemo(() => {
    const _box = new Box3();
    _box.setFromObject(scenes);
    return _box;
  }, [scenes]);

  // check has emergency event
  const matchingEmergencyEvent = useCheckModelEmergency(scenes.name);

  const changeStatusEventCamAI = (status, eventId) => {
    dispatch(
      eventSlice.actions.updateStatusEventCamAi({
        eventId,
        status,
      })
    );
  };

  return (
    <>
      {hovered && hasMappingModel && (
        <box3Helper
          args={
            isModelSelected
              ? [blockBounds, baseModelSelectedColor]
              : [blockBounds, baseModelHoverColor]
          }
        />
      )}
      {areaModel?.includes("_") &&
        !isModelSelected &&
        (hasMappingModel ||
          pathname === "/floor-4" ||
          pathname === "/floor-1") && (
          <FilledBox
            blockBounds={blockBounds}
            color="black"
            opacity={0.7}
            onClick={() => hasMappingModel && handleSelect(scenes)}
          />
        )}
      {pathname === "/" && hasMappingModel && (
        <>
          <BlinkBox
            blockBounds={blockBounds}
            enable={matchingEmergencyEvent}
            onClick={() => {
              if (
                matchingEmergencyEvent?.matchType === "FENCE" ||
                matchingEmergencyEvent?.matchType === "FENCE_BY_LIDAR"
              ) {
                setIsShowImage(true);
              }
              handleSelect(scenes);
            }}
          />
          {matchingEmergencyEvent && (
            <Html>
              <DialogImage
                src={
                  matchingEmergencyEvent?.aiImages?.[0] //?? "https://www.shutterstock.com/image-vector/picture-icon-260nw-323592404.jpg"
                }
                isOpen={isShowImage}
                onClose={() => setIsShowImage(false)}
                title={"Hình ảnh sự kiện"}
                topContent={
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {EVENT_ACTION_LIST.map((status) => (
                      <Button
                        key={status}
                        variant="outlined"
                        sx={{
                          marginRight: "12px",
                          marginBottom: "12px",
                          color: "#fff",
                          fontWeight: 500,
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        onClick={() =>
                          changeStatusEventCamAI(
                            status,
                            matchingEmergencyEvent.id
                          )
                        }
                      >
                        {getStatusActionIcon(status)} {getStatusLabel(status)}
                      </Button>
                    ))}
                  </Box>
                }
              />
            </Html>
          )}
          <FocusedBlinkBox
            sceneName={scenes.name}
            blockBounds={blockBounds}
            onClick={() => handleSelect(scenes)}
          />
        </>
      )}
      <BaseGroup
        group={scenes}
        blockBounds={blockBounds}
        controlRef={ref}
        isVisibleDetail={(hovered || isModelSelected) && hasMappingModel}
        onPointerOver={(event) => {
          event.stopPropagation();
          if (hasMappingModel) {
            hover(true);
          }
        }}
        onPointerOut={() => hover(false)}
        onClick={() => {
          if (
            matchingEmergencyEvent?.matchType === "FENCE" ||
            matchingEmergencyEvent?.matchType === "FENCE_BY_LIDAR"
          ) {
            setIsShowImage(true);
          }
          handleSelect(scenes);
        }}
      />
    </>
  );
};

const BaseSplittedModel = ({ scenes, ...props }) => {
  const model = useMemo(
    () =>
      scenes?.children[0]?.children?.map((scene) => {
        if (scene.type === "PerspectiveCamera") {
          return null;
        }
        if (scene.children.length >= 1) {
          return <GroupMesh key={scene.name} scenes={scene} />;
        }
        return <Item key={scene.name} item={scene} />;
      }),
    [scenes?.children]
  );

  return (
    <group
      {...props}
      dispose={null}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   console.log(e.camera.position);
      //   console.log(e.point);
      // }}
    >
      <group rotation={[-Math.PI / 2, 0, 0]}>{model}</group>
    </group>
  );
};

const BaseNoSplittedModel = ({ scenes, ...props }) => {
  return <primitive object={scenes} {...props} />;
};

export const BaseModel = ({ isSplitted, ...props }) => {
  return isSplitted ? (
    <BaseSplittedModel {...props} />
  ) : (
    <BaseNoSplittedModel {...props} />
  );
};
