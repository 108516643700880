import { Typography } from "@mui/material";

import NavCollapse from "./NavCollapse";
import NavItem from "./NavItem";

export const renderMenuChildren = (children, level = 0) => {
  return children?.map((item) => {
    if (item.type === "collapse") {
      return <NavCollapse key={item.id} menu={item} level={level + 1} />;
    }
    if (item.type === "item") {
      return <NavItem key={item.id} item={item} level={level + 1} />;
    }
    return (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Menu Items Error
      </Typography>
    );
  });
};
