import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useActiveArea = () => {
  const { pathname } = useLocation();
  const floorModel = pathname.startsWith("/floor-");
  const { areaDetail, selectedArea } = useSelector((state) => state.areas);

  const areaCode = floorModel
    ? selectedArea
      ? selectedArea?.areaCode
      : areaDetail?.areaCode
    : null;

  const areaName = floorModel
    ? selectedArea
      ? selectedArea?.name
      : areaDetail?.areaName
    : null;

  const areaModel = floorModel
    ? selectedArea
      ? selectedArea?.model
      : areaDetail?.model
    : null;

  const areaId = floorModel
    ? selectedArea
      ? selectedArea?.id
      : areaDetail?.id
    : null;

  return { areaCode, areaName, areaModel, areaId };
};

export default useActiveArea;
