import { useCallback } from "react";

import {
  mqttConnect,
  mqttDisconnect,
  mqttSub,
} from "../services/commonService/mqtt";

const useMqtt = () => {
  const connect = useCallback(() => {
    mqttConnect();
  }, []);

  const disconnect = useCallback(() => {
    mqttDisconnect();
  }, []);

  const subscribe = useCallback((topic) => {
    mqttSub(topic);
  }, []);

  return {
    connect,
    disconnect,
    subscribe,
  };
};

export default useMqtt;
