import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const NavItem = ({ item, level }) => {
  const theme = useTheme();

  const Icon = item.icon;
  const itemIcon = Icon ? (
    <Icon stroke={1.5} size="1rem" fontSize="small" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: 6,
        height: 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  const itemTarget = item.target ? "_blank" : "_self";
  const listItemProps = { component: "a", target: itemTarget };

  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={{
          borderRadius: `10px`,
          py: 0.25,
          alignItems: "flex-start",
          pl: `${level * 16}px`,
        }}
        onClick={() => item.onClick?.(item)}
        onMouseEnter={() => item.onMouseEnter?.(item)}
        onMouseLeave={() => item.onMouseLeave?.(item)}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !item?.icon ? 18 : 36,
            color: item.iconColor ?? "#ffffffcc",
          }}
        >
          {itemIcon}
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography variant={"body1"} color="#ffffffcc" fontSize={"0.9rem"}>
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                fontSize={"0.85rem"}
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
      </ListItemButton>
      <Divider sx={{ mt: 0.15, mb: 0.15 }} />
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
