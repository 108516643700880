import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import useActiveArea from "../../../hooks/useActiveArea";
import {
  getAllowedPeopleInArea,
  getCurrentPeopleInArea,
} from "../../../services/area.service";
import PeopleDialog from "./PeopleDialog";

const CardAreaStatus = () => {
  const { areaName, areaId } = useActiveArea();
  const [numPeople, setNumPeople] = useState(0);
  const [allowedPeople, setAllowedPeople] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const allowPeopleDialog = numPeople > 0;

  useEffect(() => {
    if (!areaId) return;

    getCurrentPeopleInArea(areaId)
      .then((resp) => setNumPeople(resp?.data?.data?.[areaId] ?? 0))
      .catch(() => setNumPeople(0));

    getAllowedPeopleInArea(areaId)
      .then((resp) => setAllowedPeople(resp?.data?.data?.[areaId] ?? 0))
      .catch(() => setAllowedPeople(0));
  }, [areaId]);

  return areaId ? (
    <>
      <Card
        sx={{
          backgroundColor: "#18403ccd",
          color: "#fefefe",
          borderWidth: 2,
          marginTop: "12px",
        }}
        variant="outlined"
      >
        <Stack>
          <Box sx={{ display: "inline-flex", justifyContent: "center" }}>
            <Typography
              sx={{ color: "#23A9C6", fontSize: 15, fontWeight: 500, p: 1 }}
            >
              {areaName ?? "Khu vực"}
            </Typography>
          </Box>
          <Divider sx={{ mt: 0.15, mb: 0.15 }} variant="middle" />

          <Stack direction={"row"}>
            <Stack sx={{ p: 1 }} alignItems="center">
              <Typography sx={{ color: "white" }}>Số người</Typography>
              <Typography sx={{ color: "white" }}>cho phép</Typography>
              <Typography
                sx={{ fontFamily: "Digital", color: "#5DD6F3", fontSize: 32 }}
              >
                {allowedPeople}
              </Typography>
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ marginX: 0.5 }} />

            <Stack
              sx={{ p: 1, cursor: allowPeopleDialog ? "pointer" : "default" }}
              alignItems="center"
              onClick={() => allowPeopleDialog && setIsOpenDialog(true)}
            >
              <Typography
                sx={{ color: allowPeopleDialog ? "#5DD6F3" : "white" }}
              >
                Số người
              </Typography>
              <Typography
                sx={{ color: allowPeopleDialog ? "#5DD6F3" : "white" }}
              >
                hiện tại
              </Typography>
              <Typography
                sx={{ fontFamily: "Digital", color: "#5DD6F3", fontSize: 32 }}
              >
                {numPeople}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      {allowPeopleDialog && (
        <PeopleDialog
          areaId={areaId}
          open={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
        />
      )}
    </>
  ) : null;
};

export default CardAreaStatus;
