import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { setEditMode, setNeedSave } from "../../toolkits/annotation/slice";
import {
  DEFAULT_EDIT_ADD,
  DEFAULT_EDIT_NONE,
  DEFAULT_EDIT_REMOVE,
} from "../../utils";

const Editor = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { editMode, needSave } = useSelector((state) => state.annotations);

  const handleClickMode = (mode) => {
    if (mode !== editMode) {
      dispatch(setEditMode(mode));
    } else {
      dispatch(setEditMode(DEFAULT_EDIT_NONE));
    }
  };

  const handleSaveCamera = () => {
    // TODO
    console.log("handle save");
    dispatch(setNeedSave(false));
  };

  // Reset editor on route change
  React.useEffect(() => {
    dispatch(setEditMode(DEFAULT_EDIT_NONE));
    dispatch(setNeedSave(false));
  }, [location.pathname, dispatch]);

  return (
    <Stack direction="column" spacing="8px">
      <Stack
        direction="column"
        sx={{
          backgroundColor: "#18403ccd",
          borderRadius: "8px",
          color: "#ffffffcc",
          overflow: "hidden",
        }}
      >
        <Button
          key="add"
          variant="text"
          color="inherit"
          startIcon={<AddCircleOutlineIcon />}
          style={{
            display: "flex",
            justifyContent: "start",
            paddingLeft: 12,
            backgroundColor:
              editMode === DEFAULT_EDIT_ADD ? "#ffffff27" : "transparent",
          }}
          onClick={() => handleClickMode(DEFAULT_EDIT_ADD)}
        >
          Thêm điểm
        </Button>
        <Button
          key="remove"
          variant="text"
          color="inherit"
          startIcon={<DeleteOutlineIcon />}
          style={{
            display: "flex",
            justifyContent: "start",
            paddingLeft: 12,
            backgroundColor:
              editMode === DEFAULT_EDIT_REMOVE ? "#ffffff27" : "transparent",
          }}
          onClick={() => handleClickMode(DEFAULT_EDIT_REMOVE)}
        >
          Xoá điểm
        </Button>
      </Stack>

      {needSave && (
        <Stack
          direction="column"
          spacing="8px"
          sx={{
            backgroundColor: "#18403ccd",
            borderRadius: "8px",
            color: "#ffffffcc",
            overflow: "hidden",
          }}
        >
          <Button
            key="save"
            variant="text"
            color="inherit"
            startIcon={<SaveIcon />}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: 12,
            }}
            onClick={handleSaveCamera}
          >
            Lưu
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Editor;
