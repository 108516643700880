import createApiService from "./commonService/baseApiService";

const api = createApiService({
    baseURL: process.env.REACT_APP_API_URL,
});

export const getListDevices = (params) => {
    return api.makeRequest({
        url: "devices",
        method: "GET",
        params,
    });
};

export const getUserByDevice = (device) => {
    return api.makeRequest({
        url: `devices/${device.id}/users`,
        method: "GET",
    });
};

export const getDeviceInfo = (device) => {
    return api.makeRequest({
        url: `devices/${device.id}/info`,
        method: "GET",
    });
};

export const getDeviceInfoById = (device) => {
    return api.makeRequest({
        url: `devices/${device.id}`,
        method: "GET",
    });
};

export const getDevicesByArea = (areaCode) => {
    return api.makeRequest({
        url: `devices/find_by_area/${areaCode}`,
        method: "GET",
    });
};

export const getDoorStatus = () => {
    return api.makeRequest({
        url: `devices/door-status`,
        method: "GET",
    })
}
