import { Box, Card, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setOptionShowLabel } from "../../toolkits/annotation/slice";
import {
  DEFAULT_ANNOTATION_TYPE_ALL,
  DEFAULT_ANNOTATION_TYPE_CAMERA,
  DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
  DEFAULT_ANNOTATION_TYPE_LIDAR,
  DEFAULT_ANNOTATION_TYPE_TANG,
  getIconForAnnotation,
} from "../../utils";

const options = [
  { value: DEFAULT_ANNOTATION_TYPE_ALL, label: "Tất cả" },
  { value: DEFAULT_ANNOTATION_TYPE_CAMERA, label: "Camera" },
  { value: DEFAULT_ANNOTATION_TYPE_LIDAR, label: "Lidar" },
  { value: DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU, label: "Hàng rào điện tử" },
  { value: DEFAULT_ANNOTATION_TYPE_TANG, label: "Cảnh báo tầng" },
];

const ToggleShowLabel = () => {
  const dispatch = useDispatch();
  const { optionShowLabel } = useSelector((state) => state.annotations);

  return (
    <Card
      sx={{
        backgroundColor: "#18403ccd",
        display: "inline-block",
        marginBottom: "8px",
        marginLeft: "1px",
        padding: "4px 9px",
      }}
    >
      <Box
        display="inline-flex"
        flexDirection="row"
        alignItems="center"
        sx={{ pointerEvents: "all" }}
      >
        {options.map((opt) => {
          const Icon = getIconForAnnotation(opt.value);
          const isActive = optionShowLabel === opt.value;

          return (
            <Tooltip title={opt.label} placement="top" key={opt.value}>
              <Box
                sx={{
                  cursor: "pointer",
                  color: "#ffffffcd",
                  backgroundColor: isActive ? "#18403ccd" : "transparent",
                  width: 45,
                  height: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  if (opt.value === optionShowLabel) {
                    dispatch(setOptionShowLabel(null));
                  } else {
                    dispatch(setOptionShowLabel(opt.value));
                  }
                }}
              >
                {opt.value === DEFAULT_ANNOTATION_TYPE_ALL ? (
                  <Typography sx={{ fontSize: 21 }}>All</Typography>
                ) : (
                  <Icon />
                )}
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </Card>
  );
};

export default ToggleShowLabel;
