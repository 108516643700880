import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { renderMenuChildren } from "../util";

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const menus = renderMenuChildren(menu.children, level);

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size="1rem"
      style={{ marginTop: "auto", marginBottom: "auto", color: "#ffffffcc" }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `10px`,
          px: 2,
          py: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !menu.icon ? 18 : 36,
            color: "#ffffffcc",
          }}
        >
          {menuIcon}
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography
              variant={"body1"}
              color="#ffffffcc"
              fontSize={"0.9rem"}
              sx={{ my: "auto" }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                fontSize={"0.85rem"}
                display="block"
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />

        {open ? (
          <ArrowDropUpIcon
            stroke={1.5}
            size="1rem"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              color: "#ffffffcc",
            }}
          />
        ) : (
          <ArrowDropDownIcon
            stroke={1.5}
            size="1rem"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              color: "#ffffffcc",
            }}
          />
        )}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "24px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 0.5,
              background: "#ffffff3f",
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
