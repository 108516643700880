import { DEFAULT_INVALID_COORDINATE } from "../utils";
import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

export const getListFloor = (params) => {
  return api.makeRequest({
    url: "floors",
    method: "GET",
    params,
  });
};

export const getFloorDetail = (floorId) => {
  return api.makeRequest({
    url: `floors/${floorId}`,
    method: "GET",
  });
};

// TODO: update later, not build body manually
export const updateFloorDetail = (floor) => {
  const floorId = floor.id;
  const newFloor = {
    areaId: floor?.areaId ?? "",
    floorDes: floor?.description ?? "",
    floorName: floor?.floorName ?? "",
    floorPosX: String(floor?.floorPos?.x ?? DEFAULT_INVALID_COORDINATE),
    floorPosY: String(floor?.floorPos?.y ?? DEFAULT_INVALID_COORDINATE),
    floorPosZ: String(floor?.floorPos?.z ?? DEFAULT_INVALID_COORDINATE),
    floorTitle: floor?.title ?? "",
    imageUrl: floor?.imageUrl ?? "",
    libraryId: floor?.libraryId ?? "",
    lookPosX: String(floor?.lookAt?.x ?? DEFAULT_INVALID_COORDINATE),
    lookPosY: String(floor?.lookAt?.y ?? DEFAULT_INVALID_COORDINATE),
    lookPosZ: String(floor?.lookAt?.z ?? DEFAULT_INVALID_COORDINATE),
  };
  return api.makeRequest({
    url: `floors/${floorId}`,
    method: "PUT",
    data: newFloor,
  });
};
