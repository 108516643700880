import { Html } from "@react-three/drei";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useSelectAnnotation from "../../hooks/useSelectAnnotation";
import {
  DEFAULT_ANNOTATION_TYPE_ALL,
  DEFAULT_ANNOTATION_TYPE_CAMERA,
  DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
  DEFAULT_ANNOTATION_TYPE_LIDAR,
  DEFAULT_ANNOTATION_TYPE_TANG,
  DEFAULT_EDIT_NONE,
  DEFAULT_EDIT_REMOVE,
  checkEnableStream,
  checkAnnotationHasEmergency,
  hasPosition,
} from "../../utils";
import { setEditMode } from "../../toolkits/annotation/slice";
import { updateCamera } from "../../toolkits/camera/slice";
import { updateFence } from "../../toolkits/fence/slice";
import { updateFloor } from "../../toolkits/floor/slice";
import { updateLidar } from "../../toolkits/lidar/slice";
import AnnotationConent from "./AnnotationContent";
import "./index.css";
import useEmergencyEvents from "../../hooks/useEmergencyEvents";

function Annotations({ controls }) {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.events);
  const { optionShowLabel } = useSelector((state) => state.annotations);
  const { cameras } = useSelector((state) => state.cameras);
  const { lidars } = useSelector((state) => state.lidars);
  const { fences } = useSelector((state) => state.fences);
  const { floors } = useSelector((state) => state.floors);
  const { editMode, selectedAnnotation, focusedAnnotation } = useSelector(
    (state) => state.annotations
  );
  const { handleSelect, handleUnSelect } = useSelectAnnotation(controls);
  const [hoveredAnnotation, setHoveredAnnotation] = useState(null);

  const filterCameras = useMemo(
    () =>
      cameras
        ?.filter((camera) => hasPosition(camera))
        ?.map((camera) => ({
          ...camera,
          annotationType: DEFAULT_ANNOTATION_TYPE_CAMERA,
        })) ?? [],
    [cameras]
  );
  const filterLidars = useMemo(
    () =>
      lidars
        ?.filter((lidar) => hasPosition(lidar))
        ?.map((lidar) => ({
          ...lidar,
          annotationType: DEFAULT_ANNOTATION_TYPE_LIDAR,
        })) ?? [],
    [lidars]
  );
  const filterFences = useMemo(
    () =>
      fences
        ?.filter((fence) => hasPosition(fence))
        ?.map((fence) => ({
          ...fence,
          annotationType: DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
        })) ?? [],
    [fences]
  );
  const filterFloors = useMemo(
    () =>
      floors
        ?.filter((floor) => hasPosition(floor))
        ?.map((floor) => ({
          ...floor,
          annotationType: DEFAULT_ANNOTATION_TYPE_TANG,
        })) ?? [],
    [floors]
  );
  const annotations = useMemo(
    () => [...filterCameras, ...filterLidars, ...filterFences, ...filterFloors],
    [filterCameras, filterLidars, filterFences, filterFloors]
  );

  const handleClickAnnotation = (a) => {
    if (editMode === DEFAULT_EDIT_REMOVE) {
      const newItem = {
        ...a,
        camPos: undefined,
        lidarPos: undefined,
        fencePos: undefined,
        floorPos: undefined,
        lookAt: undefined,
      };

      if (a.annotationType === DEFAULT_ANNOTATION_TYPE_CAMERA) {
        dispatch(updateCamera(newItem));
      } else if (a.annotationType === DEFAULT_ANNOTATION_TYPE_LIDAR) {
        dispatch(updateLidar(newItem));
      } else if (a.annotationType === DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU) {
        dispatch(updateFence(newItem));
      } else if (a.annotationType === DEFAULT_ANNOTATION_TYPE_TANG) {
        dispatch(updateFloor(newItem));
      }

      dispatch(setEditMode(DEFAULT_EDIT_NONE));
    } else if (selectedAnnotation?.id !== a.id) {
      handleSelect(a);
    } else {
      handleUnSelect();
    }
  };

  const emergencyEvents = useEmergencyEvents(events);

  return (
    <>
      {annotations?.map((a) => {
        const hasEmergencyLight = checkAnnotationHasEmergency(a, emergencyEvents);

        return (
          <Html key={a.id} position={[a.lookAt.x, a.lookAt.y, a.lookAt.z]}>
            <div
              className={hasEmergencyLight ? "emergency-light" : ""}
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                border: "2px solid #fff",
                backgroundColor: "rgba(0,0,0,0.56)",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => handleClickAnnotation(a)}
              onMouseEnter={() => setHoveredAnnotation(a)}
              onMouseLeave={() => setHoveredAnnotation(null)}
            >
              <AnnotationConent
                annotationType={a.annotationType}
                isShowTitle={
                  focusedAnnotation?.id === a.id ||
                  optionShowLabel === DEFAULT_ANNOTATION_TYPE_ALL ||
                  optionShowLabel === a.annotationType ||
                  hoveredAnnotation?.id === a.id
                }
                title={a.title}
                isEnableStream={
                  (a.camUrl && checkEnableStream(a.status, a.camUrl)) ||
                  (a.cameraInfos &&
                    checkEnableStream(
                      a.cameraInfos[0]?.status,
                      a.cameraInfos[0]?.camUrl
                    ))
                }
              />
            </div>
          </Html>
        );
      })}
    </>
  );
}

export default Annotations;
