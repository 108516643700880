import { OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { shouldForwardProp } from "@mui/system";

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: "90%",
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 24,
    height: "2rem",
    color: "#fff",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff1f",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff3b",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

export const CameraCartWidgetStyle = styled('div')(({ theme }) => ({
  zIndex: 100,
  right: 20,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: `0 20px 40px -4px alpha('#919EAB', 0.16)`,
  color: '#212B36',
  backgroundImage: `linear-gradient(180deg, #535760, #8c92a1)`,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));