import { all, call, put, takeEvery } from "redux-saga/effects";

import { getListFence, updateFenceDetail } from "../../services/fence.service";
import alertSlice from "../alert/slice";
import { getFences, setFences, updateFence, updateFenceSuccess } from "./slice";

function* getFencesSaga({ payload }) {
  try {
    const response = yield call(getListFence, payload);
    if (response.status === 200) {
      const respData = response.data;
      yield put(
        setFences(
          respData?.data?.rows?.map((item) => ({
            ...item,
            camPos: item.fencePos,
          })) ?? []
        )
      );
    } else {
      yield put(
        alertSlice.actions.error(
          "Lỗi xảy ra khi tải danh sách hàng rào điện tử"
        )
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(
      alertSlice.actions.error("Lỗi xảy ra khi tải danh sách hàng rào điện tử")
    );
    console.log(error);
  }
}

function* updateFenceSaga({ payload }) {
  try {
    const response = yield call(updateFenceDetail, payload);

    if (response.status === 200) {
      yield put(
        alertSlice.actions.success("Cập nhật hàng rào điện tử thành công")
      );
      yield put(updateFenceSuccess(payload));
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi cập nhật hàng rào điện tử")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(
      alertSlice.actions.error("Lỗi xảy ra khi cập nhật hàng rào điện tử")
    );
    console.log(error);
  }
}

export default function* saga() {
  yield all([
    takeEvery(getFences().type, getFencesSaga),
    takeEvery(updateFence().type, updateFenceSaga),
  ]);
}
