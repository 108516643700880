// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useDispatch } from "react-redux";

import { IconLogout } from "@tabler/icons-react";
import DateTime from "../../components/StatusBar/DateTime";
import authSlice from "../../toolkits/auth/slice";
import AppMenu from "./menu";
import {
  MenuWrapper,
  NavBar,
  NavBarWrapper,
  NavLogo,
  NavMain,
  NavSpan,
} from "./narbar.style";
import logo from "../../assets/logo.png"

const Navbar = () => {
  const dispatch = useDispatch();
  const onLogout = (e) => {
    dispatch(authSlice.actions.logout());
  };
  return (
    <NavBarWrapper>
      <NavBar>
        <NavMain>
          <NavSpan>
            <NavLogo>
              <img
                src={logo}
                alt="spro"
                style={{ height: "36px", width: "auto", marginTop: 3 }}
              />
            </NavLogo>
          </NavSpan>
        </NavMain>

        <NavMain>
          <AppMenu />
        </NavMain>

        {/* <ToolsWrapper>
          <IconButton size="large" color="inherit">
            <Badge color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton size="large" color="inherit">
            <AccountCircle />
          </IconButton>
        </ToolsWrapper> */}

        <MenuWrapper>
          <DateTime />
        </MenuWrapper>
        <MenuWrapper>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={onLogout}
          >
            <IconLogout stroke={2} size="1.5rem" color="#fff" />
          </IconButton>
        </MenuWrapper>
      </NavBar>
    </NavBarWrapper>
  );
};

export default Navbar;
