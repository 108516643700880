import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_API_URL,
});

export const login = (payload) => {
  return api.makeRequest({
    url: "users/authenticate",
    method: "POST",
    data: payload,
  });
};

export const logout = () => {
  return api.makeRequest({
    url: "users/logout",
    method: "POST",
  });
};

// export const checkLogin = (payload) => {
//   return api.makeRequest({
//     url: "auth/check",
//     method: "GET",
//     data: payload,
//   });
// };
