import { all } from "redux-saga/effects";

import alertSaga from "./alert/saga";
import areaSaga from "./area/saga";
import authSaga from "./auth/saga";
import cameraSaga from "./camera/saga";
import eventSaga from "./event/saga";
import fenceSaga from "./fence/saga";
import lidarSaga from "./lidar/saga";
import floorSaga from "./floor/saga";

export default function* rootSaga() {
  yield all([
    eventSaga(),
    authSaga(),
    alertSaga(),
    cameraSaga(),
    areaSaga(),
    fenceSaga(),
    lidarSaga(),
    floorSaga(),
  ]);
}
