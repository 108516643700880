import { useMemo } from "react";
import { useSelector } from "react-redux";

import { isLidarEvent, mapLidarEventWithNearestFence } from "../utils";

const useLidarEmergencyEventsWithFence = (emergencyEvents = []) => {
  const { fences } = useSelector((state) => state.fences);

  const emergencyLidarEvents = useMemo(
    () =>
      emergencyEvents
        .filter((event) => isLidarEvent(event))
        .filter((event) => event.objLat && event.objLong)
        .map((event) => mapLidarEventWithNearestFence(event, fences)),
    [emergencyEvents, fences]
  );

  return emergencyLidarEvents;
};

export default useLidarEmergencyEventsWithFence;
