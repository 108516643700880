import {
  CloseOutlined,
  Crop169Outlined,
  Crop54Outlined,
  DragIndicatorOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";

import { selectAnnotation } from "../../toolkits/annotation/slice";
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH } from "../../utils";
import AnnotationView from "./AnnotationView";

const SCALE_FACTOR_DEFAULT = 1;
const SCALE_FACTOR_LARGER = 1.5;

const AnnotationDialog = () => {
  const dispatch = useDispatch();
  const { selectedAnnotation } = useSelector((state) => state.annotations);
  const [scaleFactor, setScaleFactor] = useState(SCALE_FACTOR_DEFAULT);

  const videoWidth = DEFAULT_VIDEO_WIDTH * scaleFactor;
  const videoHeight = DEFAULT_VIDEO_HEIGHT * scaleFactor;

  const handleClose = () => {
    dispatch(selectAnnotation(null));
  };

  return selectedAnnotation ? (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Box
        position="fixed"
        top="13%"
        left="50%"
        zIndex={1000}
        textAlign="center"
        bgcolor="rgba(0, 0, 0, 0.66)"
        borderRadius="0.25rem"
        padding="2px"
      >
        <Box
          padding={1}
          height={14}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="horizontal"
            style={{ cursor: "move", flex: 1 }}
            id="draggable-dialog-title"
          >
            <DragIndicatorOutlined />

            <Typography
              sx={{
                width: `${videoWidth - 100}px`,
                display: "-webkit-box",
                overflow: "hidden",
                textAlign: "left",
                paddingLeft: 1,
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {selectedAnnotation.title}
            </Typography>
          </Stack>

          {scaleFactor !== SCALE_FACTOR_DEFAULT && (
            <IconButton
              color="inherit"
              onClick={() => setScaleFactor(SCALE_FACTOR_DEFAULT)}
            >
              <Crop169Outlined />
            </IconButton>
          )}
          {scaleFactor !== SCALE_FACTOR_LARGER && (
            <IconButton
              color="inherit"
              onClick={() => setScaleFactor(SCALE_FACTOR_LARGER)}
            >
              <Crop54Outlined />
            </IconButton>
          )}
          <CloseOutlined onClick={handleClose} style={{ cursor: "pointer" }} />
        </Box>
        <AnnotationView
          annotation={selectedAnnotation}
          videoWidth={videoWidth}
          videoHeight={videoHeight}
        />
      </Box>
    </Draggable>
  ) : null;
};

export default AnnotationDialog;
