import { useMemo } from "react";
import {
  DEFAULT_STATUS_COMPLETED_SKIP,
  DEFAULT_STATUS_NEW,
  DEFAULT_STATUS_PROCESSING,
  isFenceEvent,
  isLidarEvent,
} from "../utils";

const useEventStatistic = (events) => {
  const countNew = useMemo(() => {
    return (
      events?.filter((event) => event.status === DEFAULT_STATUS_NEW)?.length ??
      0
    );
  }, [events]);

  const countProcessing = useMemo(() => {
    return (
      events?.filter((event) => event.status === DEFAULT_STATUS_PROCESSING)
        ?.length ?? 0
    );
  }, [events]);

  const countCompletedSkip = useMemo(() => {
    return (
      events?.filter((event) => event.status === DEFAULT_STATUS_COMPLETED_SKIP)
        ?.length ?? 0
    );
  }, [events]);

  const countLidar = useMemo(
    () => events?.filter((event) => isLidarEvent(event))?.length ?? 0,
    [events]
  );

  const countFence = useMemo(
    () => events?.filter((event) => isFenceEvent(event)).length ?? 0,
    [events]
  );

  return {
    countNew,
    countProcessing,
    countCompletedSkip,
    countLidar,
    countFence,
  };
};

export default useEventStatistic;
