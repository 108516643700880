import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";

import DialogImage from "../DialogImage";

// Custom styled component for the thumbnail image
const ThumbnailImage = styled("img")({
  cursor: "pointer",
  maxWidth: "100%",
  maxHeight: "100%",
  borderRadius: "8px",
  //   transition: "transform 0.3s ease-in-out",
  //   "&:hover": {
  //     transform: "scale(1.1)",
  //   },
});

const ClickToShowFullImage = ({ thumbnailSrc, fullSizeSrc, alt }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ThumbnailImage src={thumbnailSrc} alt={alt} onClick={handleOpen} />

      <DialogImage
        src={fullSizeSrc}
        alt={alt}
        isOpen={open}
        onClose={handleClose}
        title={"Hình ảnh sự kiện"}
      />
    </Box>
  );
};

export default ClickToShowFullImage;
