import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  getEventList,
  getEventDeviceLogs,
  updateStatusEventCamAI,
  getEventCamAiWithFilter,
  getEventType
} from "../../services/event.service";
import { getListDevices, getDoorStatus } from "../../services/device.service";
import eventSlice from "./slice";
import alertSlice from "../alert/slice";
import { setLocalData } from "../../services/localStorage";

function* getEventListSaga() {
  const state = yield select((state) => state.events);
  const { pageNumber, pageSize, keyword } = state;
  try {
    const response = yield call(getEventList, pageNumber, pageSize, keyword);
    // console.log(response)
    if (response.status === 200) {
      const data = response.data;
      if (data) {
        yield put(eventSlice.actions.getEventSuccess(data.data));
      } else {
        yield put(eventSlice.actions.getEventError());
      }
    } else {
      yield put(eventSlice.actions.getEventError());
      // yield put(alertSlice.actions.error("Không thành công"))
      console.log("API error");
    }
  } catch (error) {
    yield put(eventSlice.actions.getEventError());
    // yield put(alertSlice.actions.error("Không thành công"))
    console.log(error);
  }
}

function* getEventCamAIWithFilterSaga() {
  const state = yield select((state) => state.events);
  const { pageNumber, pageSize, keyword, camAiFilter } = state;
  // console.log(camAiFilter)
  try {
    let response = null
    if (Object.keys(camAiFilter).length === 0) yield call(getEventListSaga)
    else {
      response = yield call(
        getEventCamAiWithFilter,
        pageNumber,
        pageSize,
        keyword,
        camAiFilter
      );
      // console.log(response)
      if (response.status === 200) {
        const data = response.data;
        if (data) {
          yield put(eventSlice.actions.getEventSuccess(data.data));
        } else {
          yield put(eventSlice.actions.getEventError());
        }
      } else {
        yield put(eventSlice.actions.getEventError());
        // yield put(alertSlice.actions.error("Không thành công"))
        console.log("API error");
      }
    }
  } catch (error) {
    yield put(eventSlice.actions.getEventError());
    // yield put(alertSlice.actions.error("Không thành công"))
    console.log(error);
  }
}

function* getEventDeviceLogsSaga() {
  const state = yield select((state) => state.events);
  const { pageNumber, pageSize, deviceLogFilter } = state;
  try {
    const response = yield call(
      getEventDeviceLogs,
      pageNumber - 1,
      pageSize,
      deviceLogFilter
    );
    // console.log(response)
    if (response.status === 200) {
      const data = response.data;
      if (data) {
        yield put(eventSlice.actions.getEventDeviceLogsSuccess(data));
      } else {
        yield put(eventSlice.actions.getEventError());
      }
    } else {
      yield put(eventSlice.actions.getEventError());
      // yield put(alertSlice.actions.error("Không thành công"))
      console.log("API error");
    }
  } catch (error) {
    yield put(eventSlice.actions.getEventError());
    // yield put(alertSlice.actions.error("Không thành công"))
    console.log(error);
  }
}

function* eventTablePageChangeSaga() {
  const state = yield select((state) => state.events);
  const { eventMode } = state;
  if (eventMode === "CAM_AI") yield call(getEventCamAIWithFilterSaga)
  else if (eventMode === "DEVICE_LOGS") yield call(getEventDeviceLogsSaga)
  else yield put(eventSlice.actions.getEventError());
}

function* updateEventCamAiSaga({ payload }) {
  try {
    const response = yield call(updateStatusEventCamAI, payload);
    // console.log(response)
    if (response.status === 200) {
      yield call(getEventCamAIWithFilterSaga)
      yield put(alertSlice.actions.success("Cập nhật thành công"));
    } else {
      yield put(eventSlice.actions.getEventError());
      // yield put(alertSlice.actions.error("Không thành công"))
      console.log("API error");
    }
  } catch (error) {
    yield put(eventSlice.actions.getEventError());
    // yield put(alertSlice.actions.error("Không thành công"))
    console.log(error);
  }
}

function* updateNotifyEventCamAISaga(payload, type) {
  const state = yield select((state) => state.events);
  const { pageNumber, pageSize, keyword, camAiFilter } = state;
  const filter =
    Object.keys(camAiFilter).length === 0
      ? payload
      : Object.assign({}, camAiFilter, payload);
  let noti = {};
  try {
    const response = yield call(
      getEventCamAiWithFilter,
      pageNumber,
      pageSize,
      keyword,
      filter
    );
    if (response.status === 200) {
      const data = response.data;
      noti[`${type}`] = data.data.count
      yield put(eventSlice.actions.getNotifyEventCamAISuccess(noti));
    } else {
      yield put(eventSlice.actions.getEventError());
      // yield put(alertSlice.actions.error("Không thành công"))
    }
  } catch (error) {
    yield put(eventSlice.actions.getEventError());
    // yield put(alertSlice.actions.error("Không thành công"))
  }
}

function* updateCountEventCamAi() {
  yield fork(updateNotifyEventCamAISaga, { status: "NEW" }, "countNew");
  yield fork(
    updateNotifyEventCamAISaga,
    { status: "PROCESSING" },
    "countAllow"
  );
  yield delay(1000);
}

function* getNotifyEventCamAiSaga() {
  try {
    yield call(updateCountEventCamAi);
  } catch (error) {
    // yield put(eventSlice.actions.getEventError());
    console.log(error);
    // yield put(alertSlice.actions.error("Không thành công"))
  }
}

function* addEventMqtt2LocalSaga() {
  const state = yield select((state) => state.events);
  const { cameraMqtt, lidarMqtt, fenceMqtt } = state
  try {
    setLocalData("cameraMqtt", cameraMqtt);
    setLocalData("lidarMqtt", lidarMqtt);
    setLocalData("fenceMqtt", fenceMqtt);
  } catch (error) {
    console.log(error);
  }
}

function* getEventTypeSaga() {
  try {
    const response = yield call(getEventType);
    if (response.status === 200) {
      const respData = response.data;
      yield put(eventSlice.actions.getEventTypesSuccess(respData?.data ?? []));
    } else {
      yield put(
        alertSlice.actions.error("Có lỗi xảy ra")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Có lỗi xảy ra"));
    console.log(error);
  }
}

function* getDoorStatusSaga() {
  try {
      const response = yield call(getDoorStatus);
      if (response.status === 200) {
          const respData = response.data;
          yield put(eventSlice.actions.getDoorStatusSuccess(respData));
      } else {
          yield put(
              alertSlice.actions.error("Lỗi xảy ra khi lấy danh sách")
          );
          console.log("API error");
      }
  } catch (error) {
      yield put(alertSlice.actions.error("Lỗi xảy ra khi lấy danh sách"));
      console.log(error);
  }
}

function* getDevicesSaga() {
  // eslint-disable-next-line no-unused-vars
  const state = yield select((state) => state.events);
  try {
      const response = yield call(getListDevices, { page: 0, size: 200 });
      if (response.status === 200) {
          const respData = response.data;
          yield put(eventSlice.actions.getDevicesSuccess(respData?.content));
      } else {
          yield put(
              alertSlice.actions.error("Lỗi xảy ra khi lấy danh sách thiết bị")
          );
          console.log("API error");
      }
  } catch (error) {
      yield put(alertSlice.actions.error("Lỗi xảy ra khi lấy danh sách thiết bị"));
      console.log(error);
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(
      eventSlice.actions.eventTablePageChange().type,
      eventTablePageChangeSaga
    ),
    yield takeEvery(
      eventSlice.actions.getEventDeviceLogs().type,
      getEventDeviceLogsSaga
    ),
    yield takeEvery(
      eventSlice.actions.updateStatusEventCamAi().type,
      updateEventCamAiSaga
    ),
    yield takeEvery(
      eventSlice.actions.getEventCamAIWithFilter().type,
      getEventCamAIWithFilterSaga
    ),
    yield takeEvery(
      eventSlice.actions.getNotifyEventCamAI().type,
      getNotifyEventCamAiSaga
    ),
    yield takeEvery(
      eventSlice.actions.addCameraMqtt().type,
      addEventMqtt2LocalSaga
    ),
    yield takeEvery(
      eventSlice.actions.addLidarMqtt().type,
      addEventMqtt2LocalSaga
    ),
    yield takeEvery(
      eventSlice.actions.addFenceMqtt().type,
      addEventMqtt2LocalSaga
    ),
    yield takeEvery(
      eventSlice.actions.getEventTypes().type,
      getEventTypeSaga
    ),
    yield takeEvery(
      eventSlice.actions.getDoorStatus().type,
      getDoorStatusSaga
    ),
    yield takeEvery(
      eventSlice.actions.getDevices().type,
      getDevicesSaga
    )
  ]);
}