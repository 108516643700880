import { StyledEngineProvider} from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
// import { createTheme } from "@mui/material/styles";
import ThemeProvider from "./theme";

import { router } from "./routers";
import { store } from "./toolkits/store";
import { ToastContainer } from "react-toastify";
import CustomToastContainer from "./components/Toast/CustomToast";

import "react-toastify/dist/ReactToastify.css";

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: "#466663",
//       main: "#18403c",
//       dark: "#102c2a",
//       contrastText: "#fff",
//     },
//     secondary: {
//       light: "#ff7072",
//       main: "#FF4D4F",
//       dark: "#b23537",
//       contrastText: "#fff",
//     },
//   },
// });

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <Provider store={store}>
          <RouterProvider router={router} />
          <ToastContainer />
          <CustomToastContainer />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
