import styled from "styled-components";

const CircleBlink = styled.span`
  background-color: ${(props) => props.color};
  width: ${(props) => props.weight}px;
  height: ${(props) => props.weight}px;
  display: inline-block;
  animation: blink 1s infinite;
  border-radius: 50%;
  transition: all 0.5s;

  @keyframes blink {
    0% {
      transform: scale(0.75);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.75);
      opacity: 0.5;
    }
  }
`;

export default CircleBlink;
