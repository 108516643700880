import { Divider, InputAdornment, Stack, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";

import { ExpandMore, OutlineInputStyle } from "./card.style";

function CardCustomWithSearch({
  value,
  onChange,
  children,
  cardSx = {},
  placeholder = "Tìm kiếm",
  defaultExpanded = false,
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const debouncedChange = debounce((value) => {
    onChange?.(value);
  }, 500);

  return (
    <Card
      sx={{
        minWidth: "245px",
        width: "16%",
        maxHeight: "40vh",
        display: "flex",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        backgroundColor: "#18403ccd",
        overflow: "hidden",
        pointerEvents: "all",
        color: "#fefefe",
        ...cardSx,
      }}
    >
      <Stack sx={{ overflow: "hiden" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "8px 1px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OutlineInputStyle
            defaultValue={value}
            onChange={(event) => debouncedChange(event.target.value)}
            placeholder={placeholder}
            sx={{ width: "100%" }}
            startAdornment={
              <InputAdornment style={{ height: 0.5 }} position="start">
                <IconSearch stroke={1.5} size="1rem" color="#fff" />
              </InputAdornment>
            }
          />
          <Box sx={{ padding: 1 }}>
            <ExpandMore expand={expanded} onClick={handleExpandClick}>
              <IconChevronDown stroke={1.5} size="1rem" color="#fff" />
            </ExpandMore>
          </Box>
        </Box>
        <Divider />
        <Collapse
          sx={{ overflow: "auto" }}
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          {children}
        </Collapse>
      </Stack>
    </Card>
  );
}

export default CardCustomWithSearch;
