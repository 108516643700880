import { Crop169Outlined, Crop54Outlined } from "@mui/icons-material";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setOpenLevel } from "../../toolkits/event/slice";
import {
  DEFAULT_OPEN_LEVEL_LG,
  DEFAULT_OPEN_LEVEL_MD,
  DEFAULT_OPEN_LEVEL_SM,
} from "../../utils";

const ChangeOpenLevel = () => {
  const dispatch = useDispatch();
  const { openLevel } = useSelector((state) => state.events);

  const openLevelSm = () => dispatch(setOpenLevel(DEFAULT_OPEN_LEVEL_SM));
  const openLevelMd = () => dispatch(setOpenLevel(DEFAULT_OPEN_LEVEL_MD));
  const openLevelLg = () => dispatch(setOpenLevel(DEFAULT_OPEN_LEVEL_LG));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} px={1} color="#fff">
      {openLevel !== DEFAULT_OPEN_LEVEL_SM && (
        <IconButton color="inherit" onClick={openLevelSm}>
          <Crop169Outlined />
        </IconButton>
      )}
      {openLevel !== DEFAULT_OPEN_LEVEL_MD && (
        <IconButton color="inherit" onClick={openLevelMd}>
          <Crop54Outlined />
        </IconButton>
      )}
      {openLevel !== DEFAULT_OPEN_LEVEL_LG && (
        <IconButton color="inherit" onClick={openLevelLg}>
          <OpenInFullOutlinedIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ChangeOpenLevel;
