import { createSlice } from "@reduxjs/toolkit";

import { replaceitemInList } from "../../utils";

const initialState = {
  // objectDetected: {
  //   severity: "high",
  //   angles: [],
  //   bboxes: [],
  //   camera: "Tang_2_camera_1",
  //   location: "Tang 2",
  //   cameraUrl:
  //     "rtsp://admin:Spro123456@192.168.1.31:554/Streaming/Channels/101",
  //   description: "",
  //   eventType: "OBJECT_DETECTED",
  //   image: "http://xxx",
  //   imageResult: "http://xxx",
  //   imageZoomResult: "http://xxx",
  //   camera_on_demand_url:
  //     "rtsp://admin:Spro123456@192.168.1.31:554/Streaming/Channels/101",
  //   timeEnd: "Unknown",
  //   timeStart: "Unknown",
  // },
  errorMassage: false,
  isLoading: false,
  /**
   * List camera of each area
   */
  cameras: [],
  cameraDto: {
    // page: 1,
    // limit: 100,
    // keyword: "",
  },
};

const reducer = createSlice({
  name: "cameras",
  initialState,
  reducers: {
    getCameras: (state, action) => {
      state.cameras = [];
      state.cameraDto = action.payload;
    },
    setCameras: (state, action) => {
      state.cameras = action.payload;
    },
    updateCamera: () => {},
    updateCameraSuccess: (state, action) => {
      const camera = action.payload ?? {};
      state.cameras = replaceitemInList(state.cameras, camera);
    },
  },
});

export const { getCameras, setCameras, updateCamera, updateCameraSuccess } =
  reducer.actions;

export default reducer;
