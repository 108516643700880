import axios from "axios";

import { getLocalData, removeLocalData } from "../localStorage";

const baseApi = axios.create({
  // baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

baseApi.interceptors.request.use(
  (config) => {
    const accessToken = getLocalData("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return Promise.resolve(config);
  },

  (error) => {
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },

  (error) => {
    if (
      error.response?.status === 401 &&
      !error.config?.url?.includes("/users/authenticate")
    ) {
      removeLocalData("isLoggedIn");
      removeLocalData("accessToken");
      removeLocalData("role");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const _makeRequest = (createRequest, options) => async (args) => {
  const _headers = args.headers ? args.headers : {};
  const body = args.body ? args.body : {};
  const defaultHeaders = {};

  const mergedArgs = {
    ...args,
    headers: {
      ...defaultHeaders,
      ..._headers,
      "Access-Control-Allow-Origin": "*",
    },
    body,
    ...options,
  };

  try {
    const response = await createRequest(mergedArgs);
    return response;
  } catch (e) {
    // console.log(e);
    throw e;
  }
};

const baseService = (options = {}) => {
  return {
    makeRequest: _makeRequest(baseApi, options),
  };
};

export default baseService;
