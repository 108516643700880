import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import ToastMessage from "./ToastMessage";
import useAudio from "../../../hooks/useAudio";
import BellAudio from "../../../assets/bell.mp3"

const CustomToastContainer = () => {
  const { customAlert, isModal } = useSelector((state) => state.alert);
  const { muteAudio } = useSelector((state) => state.events);
  const { onPlay } = useAudio({
    src: BellAudio,
    preload: true,
    autoplay: true,
    volume: 1,
    mute: muteAudio,
    loop: false,
    rate: 1.0,
  })

  useEffect(() => {
    onPlay.call()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModal]) 

  return (
    <motion.div
      style={{
        position: "absolute",
        top: "10%",
        left: "10%",
        width: "80%",
        transform: "translateX(-50%)",
        zIndex: 100,
      }}
      layout
    >
      <AnimatePresence>
        {isModal ? (
          <ToastMessage
            key={customAlert.id}
            color={customAlert.color}
            icon={customAlert.icon}
            message={customAlert.message}
          />
        ) : (
          <></>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default CustomToastContainer;
