import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  IconShieldCheck,
  IconSwitchHorizontal,
  IconVolume,
  IconVolumeOff,
} from "@tabler/icons-react";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventClassifyButton from "../../components/Button/EventClassifyButton";
import EventModeButton from "../../components/Button/EventModeButton";
import EventDetailModal from "../../components/Modal/EventDetailModal";
import { getListAreas } from "../../toolkits/area/slice";
import { getCameras } from "../../toolkits/camera/slice";
import eventSlice from "../../toolkits/event/slice";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_STATUS_NEW,
  DEFAULT_STATUS_PROCESSING,
  getStatusColor,
} from "../../utils";
import DateRangePickerWithButtonField from "./DateRangePicker";
import SelectItem from "./SelectItem";
import { SpanAnimatedLeft2Right, SpanAnimatedRight2Left } from "./event.style";
import { CamAiTables, DeviceLogTable, DoorStatusTable } from "./table/CustomizedTables";

export default function Events() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const windowHeight = useRef(window.innerHeight);
  const [selectedDevice, setSelectDevice] = React.useState([]);
  const [selectedArea, setSelectArea] = React.useState([]);
  const [selectedEventType, setSelectEventType] = React.useState([]);
  const {
    count,
    pageNumber,
    pageSize,
    eventMode,
    camAiFilter,
    deviceLogFilter,
    notifyCamAi,
    muteAudio,
    eventTypes,
  } = useSelector((state) => state.events);

  const { cameras } = useSelector((state) => state.cameras);
  const { areas } = useSelector((state) => state.areas);

  useEffect(() => {
    if (eventMode === "CAM_AI") {
      dispatch(eventSlice.actions.getEventCamAIWithFilter());
      dispatch(eventSlice.actions.getNotifyEventCamAI());
      dispatch(
        getCameras({
          page: 1,
          limit: 50,
          keyword: "",
          areaId: selectedArea.join(","),
        })
      );
      dispatch(getListAreas());
      dispatch(eventSlice.actions.getEventTypes());
    } else if (eventMode === "DEVICE_LOGS")
      dispatch(eventSlice.actions.getEventDeviceLogs());
    else if (eventMode === "DOOR_STATUS") {
      dispatch(eventSlice.actions.getDevices());
      dispatch(eventSlice.actions.getDoorStatus())
    }
  }, [
    dispatch,
    eventMode,
    camAiFilter,
    deviceLogFilter,
    pageSize,
    selectedArea,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (eventMode === "CAM_AI") {
        dispatch(eventSlice.actions.getEventCamAIWithFilter());
        dispatch(eventSlice.actions.getNotifyEventCamAI());
      } else if (eventMode === "DEVICE_LOGS")
        dispatch(eventSlice.actions.getEventDeviceLogs());
      else if (eventMode === "DOOR_STATUS") {
        dispatch(eventSlice.actions.getDevices());
        dispatch(eventSlice.actions.getDoorStatus())
      }
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [dispatch, eventMode]);

  const handleChangePage = (event, newPage) => {
    dispatch(eventSlice.actions.eventTablePageChange(newPage + 1));
  };

  const switchModeClick = (mode) => {
    dispatch(eventSlice.actions.switchModeEvent(mode));
  };

  const filterByStatusEventCamAI = (status) => {
    if (status !== "")
      dispatch(eventSlice.actions.addFilterCamAi({ status: status }));
    else dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));
  };

  const changeMuteAudio = () => {
    dispatch(eventSlice.actions.changeMute());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(eventSlice.actions.changePageSize(+event.target.value));
  };

  const onDeviceSelected = (data) => {
    setSelectDevice(data);
    if (data.length === 0)
      dispatch(eventSlice.actions.removeFilterCamAI({ deviceId: "" }));
    else
      dispatch(eventSlice.actions.addFilterCamAi({ deviceId: data.join(",") }));
  };

  const onAreaSelected = (data) => {
    setSelectArea(data);
    if (data.length === 0)
      dispatch(eventSlice.actions.removeFilterCamAI({ areaId: "" }));
    else
      dispatch(eventSlice.actions.addFilterCamAi({ areaId: data.join(",") }));
  };

  const onEventTypeSelected = (data) => {
    setSelectEventType(data);
    if (data.length === 0)
      dispatch(eventSlice.actions.removeFilterCamAI({ eventType: "" }));
    else
      dispatch(
        eventSlice.actions.addFilterCamAi({ eventType: data.join(",") })
      );
  };

  const preDataDevices = (data) => {
    return data?.map((item) => {
      return {
        ...item,
        title: item.deviceName,
      };
    });
  };

  const preDataAreas = (data) => {
    return data?.map((item) => {
      return {
        ...item,
        title: item.areaName,
      };
    });
  };

  const preDataEventTypes = (data) => {
    return data?.map((item) => {
      return {
        id: item.code,
        title: item.name,
      };
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <Paper
        elevation={20}
        sx={{
          overflow: "hidden",
          height: "100%",
          background: "#031323",
          width: "100%",
          pt: 10,
          px: 0.5,
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            borderTop: 2,
            zIndex: 0,
            borderBottom: 2,
            borderColor: "#6D6E75",
            height: 40,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <SpanAnimatedLeft2Right />
          <SpanAnimatedRight2Left />
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item xs={4} alignItems={"center"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 20,
                  borderLeft: 3,
                  justifyContent: "center",
                  borderColor: theme.palette.common.white,
                }}
                px={1}
              >
                <IconButton aria-label="Warning">
                  <IconShieldCheck stroke={1.5} size="1rem" color="#ffff" />
                </IconButton>
                <Typography color="#ffff">Danh sách cảnh báo</Typography>

                <IconButton aria-label="switch mode" onClick={changeMuteAudio}>
                  {muteAudio ? (
                    <IconVolumeOff stroke={1.5} size="1rem" color="#fff" />
                  ) : (
                    <IconVolume stroke={1.5} size="1rem" color="#fff" />
                  )}
                </IconButton>
              </Box>
              {eventMode === "CAM_AI" ? (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  justifyContent={"end"}
                >
                  <SelectItem
                    data={preDataDevices(cameras)}
                    itemName={"VỊ TRÍ CẢNH BÁO"}
                    selectedItems={selectedDevice}
                    onSelect={(data) => onDeviceSelected(data)}
                  />
                  <SelectItem
                    data={preDataAreas(areas)}
                    itemName={"KHU VỰC"}
                    selectedItems={selectedArea}
                    onSelect={(data) => onAreaSelected(data)}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid
              container
              item
              xs={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <EventModeButton
                text={"Camera"}
                enable={eventMode === "CAM_AI"}
                onClick={() => switchModeClick("CAM_AI")}
              />
              <IconButton
                size="large"
                aria-label="switch"
                aria-controls="menu-appbar"
                // onClick={switchModeClick}
                color="inherit"
              >
                <IconSwitchHorizontal stroke={1.5} size="1rem" color="#ffff" />
              </IconButton>
              <EventModeButton
                text={"Thiết bị"}
                enable={eventMode === "DEVICE_LOGS"}
                onClick={() => switchModeClick("DEVICE_LOGS")}
              />
              <IconButton
                size="large"
                aria-label="switch"
                aria-controls="menu-appbar"
                // onClick={switchModeClick}
                color="inherit"
              >
                <IconSwitchHorizontal stroke={1.5} size="1rem" color="#ffff" />
              </IconButton>
              <EventModeButton
                text={"Trạng thái cửa"}
                enable={eventMode === "DOOR_STATUS"}
                onClick={() => switchModeClick("DOOR_STATUS")}
              />
            </Grid>
            <Grid
              container
              item
              xs={5}
              justifyContent={"right"}
              alignItems={"center"}
            >
              {eventMode === "CAM_AI" ? (
                <SelectItem
                  data={preDataEventTypes(eventTypes)}
                  itemName={"LOẠI SỰ KIỆN"}
                  selectedItems={selectedEventType}
                  onSelect={(data) => onEventTypeSelected(data)}
                />
              ) : null}
              {eventMode !== "DOOR_STATUS" ? <DateRangePickerWithButtonField /> :null}
              {eventMode === "CAM_AI" ? (
                <Box sx={{ display: "flex", alignItems: "center" }} px={1}>
                  <EventClassifyButton
                    color={"#ffffff"}
                    text={"Tất cả"}
                    onClick={() => filterByStatusEventCamAI("")}
                  />
                  <EventClassifyButton
                    color={getStatusColor(DEFAULT_STATUS_NEW)}
                    text={notifyCamAi.countNew}
                    onClick={() => filterByStatusEventCamAI(DEFAULT_STATUS_NEW)}
                  />
                  <EventClassifyButton
                    color={getStatusColor(DEFAULT_STATUS_PROCESSING)}
                    text={notifyCamAi.countAllow}
                    onClick={() =>
                      filterByStatusEventCamAI(DEFAULT_STATUS_PROCESSING)
                    }
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <TableContainer
          sx={{
            p: 0,
            height: windowHeight.current * 0.8,
            borderBottom: 2,
            borderColor: "#6D6E75",
          }}
        >
          {eventMode === "CAM_AI" ? (
            <CamAiTables />
          ) : eventMode === "DEVICE_LOGS" ? (
            <DeviceLogTable />
          ) : eventMode === "DOOR_STATUS" ? (
            <DoorStatusTable />
          ) : null
          }
        </TableContainer>
        {eventMode !== "DOOR_STATUS" ? <Box maxWidth="100%" display="flex" justifyContent={"right"}>
          <TablePagination
            rowsPerPageOptions={[
              DEFAULT_PAGE_SIZE * 0.5,
              DEFAULT_PAGE_SIZE,
              2 * DEFAULT_PAGE_SIZE,
            ]}
            component="div"
            sx={{ color: "#ffff", pt: 1 }}
            // labelRowsPerPage={'Hàng mỗi trang'}
            count={count}
            rowsPerPage={pageSize}
            page={pageNumber - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>: null }
      </Paper>
      <EventDetailModal />
    </div>
  );
}
