import { Badge, IconButton, Stack, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import useEventStatistic from "../../hooks/useEventStatistic";
import {
  DEFAULT_ANNOTATION_TYPE_CAMERA,
  DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
  DEFAULT_ANNOTATION_TYPE_LIDAR,
  getIconForAnnotation,
} from "../../utils";

export const GeneralEventType = () => {
  const {
    events,
    // cameraMqtt, lidarMqtt, fenceMqtt
  } = useSelector((state) => state.events);
  const { countLidar, countFence } = useEventStatistic(events);

  const IconCamera = getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_CAMERA);
  const IconLidar = getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_LIDAR);
  const IconFence = getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU);

  return (
    <Stack direction={"row"} sx={{ pr: 2 }}>
      <Tooltip title="Camera">
        <IconButton style={{ cursor: "default" }}>
          <Badge
            badgeContent={(events?.length ?? 0) - countFence - countLidar}
            sx={{ fontSize: 12, color: "white" }}
            color="ochre"
          >
            <IconCamera size="1.2rem" color="#fff" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="Lidar">
        <IconButton style={{ cursor: "default" }}>
          <Badge
            badgeContent={countLidar}
            sx={{ fontSize: 12, color: "white", px: 1 }}
            color="ochre"
          >
            <IconLidar size="1.2rem" color="#fff" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="Hàng rào điện tử">
        <IconButton style={{ cursor: "default" }}>
          <Badge
            badgeContent={countFence}
            sx={{ fontSize: 12, color: "white" }}
            color="ochre"
          >
            <IconFence size="1.2rem" color="#fff" />
          </Badge>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
