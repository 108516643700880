import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { StyledTableCell, StyledTableRow } from "./table.style";
import { useEffect, useState } from "react";

import { getPeopleDetailInArea } from "../../../services/area.service";
import alertSlice from "../../../toolkits/alert/slice";
import { useDispatch } from "react-redux";

// const getColorWithType = (type) => {
//   if (type === "STAFF") return "#52C41A";
//   if (type === "VISITOR") return "#FAAD14";
//   return "#FF4D4F";
// };

const getTextWithType = (type) => {
  if (type === "STAFF") return "Nhân viên";
  if (type === "VISITOR") return "Khách";
  return "Người lạ";
};

const Loading = ({ col }) => {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={col} align="center">
        <LinearProgress color="ochre" />
      </StyledTableCell>
    </StyledTableRow>
  );
};

const PeopleDialog = ({ areaId, open, onClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areaId || !open) return;

    setLoading(true);
    getPeopleDetailInArea(areaId)
      .then((resp) => {
        setData(resp?.data?.data ?? []);
      })
      .catch((error) => {
        console.log("get detail people error", error);
        setData([]);
        dispatch(
          alertSlice.actions.error("Lỗi xảy ra khi tải danh sách người dùng")
        );
      })
      .finally(() => {
        setLoading(false);
        // setData([
        //   { fullName: "Trần Văn An", userType: "STAFF", areaName: "Khu vực 1" },
        //   {
        //     fullName: "Nguyễn Hồng Thái",
        //     userType: "VISITOR",
        //     areaName: "Khu vực 2",
        //   },
        //   { fullName: "Phạm Tuân", userType: "OTHERS", areaName: "Khu vực 3" },
        // ]);
      });
  }, [areaId, open, dispatch]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#18403ccd",
          color: "#ffffffcc",
        },
      }}
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="subtitle1">Danh sách người hiện tại</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell width={"5%"}>#</StyledTableCell>
              <StyledTableCell>Họ và tên</StyledTableCell>
              <StyledTableCell>Phân loại</StyledTableCell>
              <StyledTableCell>Khu vực</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell scope="row">{row.fullName}</StyledTableCell>
                  <StyledTableCell scope="row" className={row.userType ?? ""}>
                    {getTextWithType(row.userType)}
                  </StyledTableCell>
                  <StyledTableCell scope="row">{row.areaName}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <Loading col={6} />
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default PeopleDialog;
