// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import { blue } from "@mui/material/colors";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPosition, setEditMode } from "../../toolkits/annotation/slice";
import { updateCamera } from "../../toolkits/camera/slice";
import { DEFAULT_EDIT_NONE, hasPosition } from "../../utils";

export const SelectCamera = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { cameras } = useSelector((state) => state.cameras);
  const { selectedPosition } = useSelector((state) => state.annotations);

  const notSetupCameras = useMemo(() => {
    return cameras?.filter((cam) => !hasPosition(cam)) ?? [];
  }, [cameras]);

  const handleListItemClick = (camera) => {
    const newCamera = {
      ...camera,
      ...selectedPosition,
      // camUrl: "rtsp://admin:888888@113.161.83.77:554/avn=2",
      // camUrl: "rtsp://192.168.3.225:8554/stream",
    };
    dispatch(updateCamera(newCamera));
    dispatch(setEditMode(DEFAULT_EDIT_NONE));
    dispatch(selectPosition());
    setOpen(false);
  };

  return (
    <List sx={{ pt: 0, minWidth: "300px" }} dense>
      {notSetupCameras.map((item) => (
        <ListItem disableGutters key={item.id}>
          <ListItemButton onClick={() => handleListItemClick(item)}>
            {/* <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <CameraAltIcon />
              </Avatar>
            </ListItemAvatar> */}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                sx: {
                  color: "#ffffffcc",
                },
              }}
              secondary={item.description}
              secondaryTypographyProps={{
                sx: {
                  color: "#ffffffa7",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
