import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import HomeIcon from "@mui/icons-material/Home";

import CardCustomWithSearch from "../../components/Card/CardCustomWithSearch";
import { renderMenuChildren } from "../../components/MenuList/util";
import LayoutContext from "../../layouts/LayoutContext";
import alertSlice from "../../toolkits/alert/slice";
import { focusAnnotation } from "../../toolkits/annotation/slice";
import {
  DEFAULT_ANNOTATION_TYPE_CAMERA,
  DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU,
  DEFAULT_ANNOTATION_TYPE_LIDAR,
  DEFAULT_ANNOTATION_TYPE_TANG,
  changeControlsTarget,
  checkEnableStream,
  filterTreeByKeyword,
  getIconForAnnotation,
  hasPosition,
} from "../../utils";

const AnnotationList = () => {
  // const { camera: threeCamera } = useThree();
  const dispatch = useDispatch();
  const { controlsRef } = useContext(LayoutContext);
  const { cameras } = useSelector((state) => state.cameras);
  const { lidars } = useSelector((state) => state.lidars);
  const { fences } = useSelector((state) => state.fences);
  const { floors } = useSelector((state) => state.floors);

  const handleItemClick = useCallback(
    (item) => {
      if (hasPosition(item)) {
        changeControlsTarget(controlsRef, item.lookAt);
        // changeCameraPosition(threeCamera, item.camPos);
        // setTimeout(() => {
        //   dispatch(selectAnnotation(item));
        // }, 800);
      } else {
        dispatch(alertSlice.actions.info("Thiết bị chưa được cài đặt"));
      }
    },
    [
      dispatch,
      controlsRef,
      // threeCamera
    ]
  );

  const handleMouseEnter = useCallback(
    (item) => {
      dispatch(focusAnnotation(item));
    },
    [dispatch]
  );

  const handleMouseLeave = useCallback(
    (item) => {
      dispatch(focusAnnotation(null));
    },
    [dispatch]
  );

  const [keyword, setKeyword] = useState("");

  const formattedCameras = useMemo(() => {
    return (
      cameras?.map((camera) => ({
        ...camera,
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_CAMERA),
        iconColor: checkEnableStream(camera.status, camera.camUrl)
          ? "#7df342"
          : "#ffffffcc",
        type: "item",
        title: camera.title,
        onClick: handleItemClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })) ?? []
    );
  }, [cameras, handleItemClick, handleMouseEnter, handleMouseLeave]);

  const formattedLidars = useMemo(() => {
    return (
      lidars?.map((lidar) => ({
        ...lidar,
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_LIDAR),
        iconColor:
          lidar.cameraInfos &&
          checkEnableStream(
            lidar.cameraInfos[0]?.status,
            lidar.cameraInfos[0]?.camUrl
          )
            ? "#7df342"
            : "#ffffffcc",
        type: "item",
        title: lidar.lidarName,
        onClick: handleItemClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })) ?? []
    );
  }, [lidars, handleItemClick, handleMouseEnter, handleMouseLeave]);

  const formattedFences = useMemo(() => {
    return (
      fences?.map((fence) => ({
        ...fence,
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU),
        iconColor:
          fence.cameraInfos &&
          checkEnableStream(
            fence.cameraInfos[0]?.status,
            fence.cameraInfos[0]?.camUrl
          )
            ? "#7df342"
            : "#ffffffcc",
        type: "item",
        title: fence.fenceName,
        onClick: handleItemClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })) ?? []
    );
  }, [fences, handleItemClick, handleMouseEnter, handleMouseLeave]);

  const formattedFloors = useMemo(() => {
    return (
      floors?.map((floor) => ({
        ...floor,
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_TANG),
        iconColor: "#ffffffcc",
        type: "item",
        title: floor.floorName,
        onClick: handleItemClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })) ?? []
    );
  }, [floors, handleItemClick, handleMouseEnter, handleMouseLeave]);

  const items = useMemo(() => {
    return [
      {
        id: "camera",
        title: "Camera",
        type: "collapse",
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_CAMERA),
        children: formattedCameras,
      },
      {
        id: "lidar",
        title: "Lidar",
        type: "collapse",
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_LIDAR),
        children: formattedLidars,
      },
      {
        id: "fence",
        title: "Hàng rào điện tử",
        type: "collapse",
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU),
        children: formattedFences,
      },
      {
        id: "floor",
        title: "Cảnh báo tầng",
        type: "collapse",
        icon: getIconForAnnotation(DEFAULT_ANNOTATION_TYPE_TANG),
        children: formattedFloors,
      },
    ];
  }, [formattedCameras, formattedLidars, formattedFences, formattedFloors]);

  const itemsFilterByKeyword = useMemo(() => {
    return filterTreeByKeyword(items, keyword);
  }, [items, keyword]);

  return (
    <CardCustomWithSearch value={keyword} onChange={setKeyword}>
      {renderMenuChildren(itemsFilterByKeyword)}
    </CardCustomWithSearch>
  );
};

export default AnnotationList;

// const cameras = {
//   id: "camera_area",
//   title: "Khu vực Camera",
//   type: "group",
//   children: [
//     {
//       id: "fence",
//       title: "Hang Rao",
//       type: "collapse",
//       icon: FenceIcon,
//       children: [
//         {
//           id: "camera-1",
//           title: "Camera 1",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-2",
//           title: "Camera 2",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-3",
//           title: "Camera 3",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-4",
//           title: "Camera 4",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//       ],
//     },
//     {
//       id: "home",
//       title: "Toa Nha",
//       type: "collapse",
//       url: "",
//       icon: HomeIcon,
//       children: [
//         {
//           id: "camera-1",
//           title: "Camera 1",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-2",
//           title: "Camera 2",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-3",
//           title: "Camera 3",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-4",
//           title: "Camera 4",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//       ],
//     },
//     {
//       id: "garage",
//       title: "Bai Xe",
//       type: "collapse",
//       icon: GarageIcon,
//       children: [
//         {
//           id: "camera-1",
//           title: "Camera 1",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-2",
//           title: "Camera 2",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-3",
//           title: "Camera 3",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//         {
//           id: "camera-4",
//           title: "Camera 4",
//           icon: CameraAltIcon,
//           type: "item",
//           url: "",
//           breadcrumbs: false,
//         },
//       ],
//     },
//   ],
// };
