import { useMemo } from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

import { palette } from "./palette";
import { typography } from './typography';
// import { overrides } from './overrides';
import { viVN } from "@mui/material/locale";

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shape: { borderRadius: 8 },
    }),
    []
  );

  const themeWithLocale = useMemo(
    () => createTheme(memoizedValue, viVN),
    [memoizedValue]
  );

  // themeWithLocale.components = overrides(themeWithLocale)

  return (
    <MUIThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
