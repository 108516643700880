import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_SAP_API_URL,
});

const apiCameraAI = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

export const getListArea = () => {
  return api.makeRequest({
    url: "area/get-list-area",
    method: "GET",
  });
};

export const getAreaById = (areaId) => {
  return api.makeRequest({
    url: `area/${areaId}`,
    method: "GET",
  });
};

export const getAreaByCode = (areaCode) => {
  return api.makeRequest({
    url: `area/area-code/${areaCode}`,
    method: "GET",
  });
};

export const getCurrentPeopleInArea = (areaId) => {
  return apiCameraAI.makeRequest({
    url: `device-logs/get-number-people?areaIds=${areaId}`,
    method: "GET",
  });
};

export const getAllowedPeopleInArea = (areaId) => {
  return apiCameraAI.makeRequest({
    url: `device-logs/get-number-allowed-people?areaIds=${areaId}`,
    method: "GET",
  });
};

export const findPeopleInArea = (keyword) => {
  return apiCameraAI.makeRequest({
    url: `device-logs?keyword=${keyword}`,
    method: "GET",
  });
};

export const getPeopleDetailInArea = (areaId) => {
  return apiCameraAI.makeRequest({
    url: `device-logs/positions/${areaId}`,
    method: "GET",
  });
};
