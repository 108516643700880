import { createSlice } from "@reduxjs/toolkit";

import { replaceitemInList } from "../../utils";

const initialState = {
  errorMassage: false,
  isLoading: false,
  fences: [],
  fenceDto: {
    // page: 1,
    // limit: 100,
    // keyword: "",
  },
};

const reducer = createSlice({
  name: "fences",
  initialState,
  reducers: {
    getFences: (state, action) => {
      state.fences = [];
      state.fenceDto = action.payload;
    },
    setFences: (state, action) => {
      state.fences = action.payload;
    },
    updateFence: () => {},
    updateFenceSuccess: (state, action) => {
      const fence = action.payload ?? {};
      state.fences = replaceitemInList(state.fences, fence);
    },
  },
});

export const { getFences, setFences, updateFence, updateFenceSuccess } =
  reducer.actions;

export default reducer;
