import { DEFAULT_INVALID_COORDINATE } from "../utils";
import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

export const getListFence = (params) => {
  return api.makeRequest({
    url: "fences",
    method: "GET",
    params,
  });
};

export const getFenceDetail = (fenceId) => {
  return api.makeRequest({
    url: `fences/${fenceId}`,
    method: "GET",
  });
};

// TODO: update later, not build body manually
export const updateFenceDetail = (fence) => {
  const fenceId = fence.id;
  const newFence = {
    areaId: fence?.areaId ?? "",
    fenceDes: fence?.description ?? "",
    fenceName: fence?.fenceName ?? "",
    ipAddress: fence?.ipAddress ?? "",
    fencePosX: String(fence?.fencePos?.x ?? DEFAULT_INVALID_COORDINATE),
    fencePosY: String(fence?.fencePos?.y ?? DEFAULT_INVALID_COORDINATE),
    fencePosZ: String(fence?.fencePos?.z ?? DEFAULT_INVALID_COORDINATE),
    fenceTitle: fence?.title ?? "",
    imageUrl: fence?.imageUrl ?? "",
    libraryId: fence?.libraryId ?? "",
    lookPosX: String(fence?.lookAt?.x ?? DEFAULT_INVALID_COORDINATE),
    lookPosY: String(fence?.lookAt?.y ?? DEFAULT_INVALID_COORDINATE),
    lookPosZ: String(fence?.lookAt?.z ?? DEFAULT_INVALID_COORDINATE),
    latitude: fence.latitude,
    longitude: fence.longitude,
    camera: fence.cameraInfos?.map((cam) => ({ ...cam, deviceName: cam.name,  })) ?? [],
  };
  return api.makeRequest({
    url: `fences/${fenceId}`,
    method: "PUT",
    data: newFence,
  });
};
