// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import { blue } from "@mui/material/colors";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPosition, setEditMode } from "../../toolkits/annotation/slice";
import { updateFloor } from "../../toolkits/floor/slice";
import { DEFAULT_EDIT_NONE, hasPosition } from "../../utils";

export const SelectTang = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { floors } = useSelector((state) => state.floors);
  const { selectedPosition } = useSelector((state) => state.annotations);

  const notSetupFloors = useMemo(() => {
    return floors?.filter((floor) => !hasPosition(floor)) ?? [];
  }, [floors]);

  const handleListItemClick = (floor) => {
    const newFloor = {
      ...floor,
      camPos: selectedPosition?.camPos,
      floorPos: selectedPosition?.camPos,
      lookAt: selectedPosition?.lookAt,
    };
    dispatch(updateFloor(newFloor));
    dispatch(setEditMode(DEFAULT_EDIT_NONE));
    dispatch(selectPosition());
    setOpen(false);
  };

  return (
    <List sx={{ pt: 0, minWidth: "300px" }} dense>
      {notSetupFloors.map((item) => (
        <ListItem disableGutters key={item.id}>
          <ListItemButton onClick={() => handleListItemClick(item)}>
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                sx: {
                  color: "#ffffffcc",
                },
              }}
              secondary={item.description}
              secondaryTypographyProps={{
                sx: {
                  color: "#ffffffa7",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
