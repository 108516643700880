import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

import CircleBlink from "../Circle/CircleBlink";

const EventClassifyButton = ({ color, text, onClick, btnColor = "ochre" }) => {
  return (
    <div style={{ paddingRight: 12 }}>
      <Button
        color={btnColor}
        size="small"
        sx={{ cursor: onClick ? "pointer" : "default", minWidth: "80px" }}
        variant="outlined"
        onClick={onClick}
        startIcon={<CircleBlink weight={15} color={color} />}
      >
        <Typography fontSize={12} pl={3} color={color}>
          {text}
        </Typography>
      </Button>
    </div>
  );
};

export default EventClassifyButton;
