import { all, call, put, takeEvery } from "redux-saga/effects";

import { getListLidar, updateLidarDetail } from "../../services/lidar.service";
import alertSlice from "../alert/slice";
import { getLidars, setLidars, updateLidar, updateLidarSuccess } from "./slice";

function* getLidarsSaga({ payload }) {
  try {
    const response = yield call(getListLidar, payload);
    if (response.status === 200) {
      const respData = response.data;
      yield put(
        setLidars(
          respData?.data?.rows?.map((item) => ({
            ...item,
            camPos: item.lidarPos,
          })) ?? []
        )
      );
    } else {
      yield put(alertSlice.actions.error("Lỗi xảy ra khi tải danh sách lidar"));
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi tải danh sách lidar"));
    console.log(error);
  }
}

function* updateLidarSaga({ payload }) {
  try {
    const response = yield call(updateLidarDetail, payload);

    if (response.status === 200) {
      yield put(alertSlice.actions.success("Cập nhật lidar thành công"));
      yield put(updateLidarSuccess(payload));
    } else {
      yield put(alertSlice.actions.error("Lỗi xảy ra khi cập nhật lidar"));
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi cập nhật lidar"));
    console.log(error);
  }
}

export default function* saga() {
  yield all([
    takeEvery(getLidars().type, getLidarsSaga),
    takeEvery(updateLidar().type, updateLidarSaga),
  ]);
}
