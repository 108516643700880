import { io } from "socket.io-client";

import { getLocalData } from "../services/localStorage/index";

const SOCKET_API_URL = process.env.REACT_APP_SOCKET_RTSP_SERVER; //"https://spro-cms-be.jmt.vn";
const accessToken = getLocalData("accessToken");

export const socket = io(SOCKET_API_URL, {
  transportOptions: {
    polling: {
      extraHeaders: { Authorization: `Bearer ${accessToken}` },
    },
  },
});
