import { Box } from "@mui/material";
import React, { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  buildFloorKeys,
  buildFloorPath,
  getActive,
  hasAdmin,
  hasSecurityDepartment,
  navigateOrOpenExternalLink,
} from "../../utils";
import MenuPopover from "./menuPopover";
import { MenuWrapper } from "./narbar.style";
import { selectArea, setArea } from "../../toolkits/area/slice";
import { setCameras } from "../../toolkits/camera/slice";
import { setLidars } from "../../toolkits/lidar/slice";
import { setFences } from "../../toolkits/fence/slice";

const cameraAIManagementUrl =
  process.env.REACT_APP_MANAGEMENT_CAMERA_AI_URL ?? "/";
const lidarManagementUrl = process.env.REACT_APP_MANAGEMENT_LIDAR_URL ?? "/";
const fenceManagementUrl = process.env.REACT_APP_MANAGEMENT_FENCE_URL ?? "/";
const floorManagementUrl = process.env.REACT_APP_MANAGEMENT_FLOOR_URL ?? "/";

const NavMenu = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const menus = useMemo(
    () => [
      {
        path: "/",
        label: "Trang chủ",
      },
      {
        path: null,
        label: "Tầng",
        children: buildFloorKeys().map((formatFloor) => {
          const floorNum = parseInt(formatFloor);
          // console.log({ floorNum });
          return {
            path: buildFloorPath(formatFloor),
            label:
              floorNum > 0 && floorNum <= 12
                ? `Tầng ${formatFloor}`
                : floorNum === 0
                ? "Tầng hầm"
                : "Sân thượng",
          };
        }),
      },
      ...(hasAdmin(role) || hasSecurityDepartment(role)
        ? [
            {
              path: "events",
              label: "Sự kiện",
            },
          ]
        : []),
      ...(hasSecurityDepartment(role)
        ? [
            {
              path: null,
              label: "Quản trị",
              children: [
                {
                  path: cameraAIManagementUrl,
                  label: "Camera AI",
                },
                {
                  path: lidarManagementUrl,
                  label: "Lidar",
                },
                {
                  path: fenceManagementUrl,
                  label: "Hàng rào điện tử",
                },
                {
                  path: floorManagementUrl,
                  label: "Cảnh báo tầng",
                },
              ],
            },
          ]
        : []),
    ],
    [role]
  );

  return (
    <MenuWrapper>
      <Box>
        {menus.map((item) => {
          if (item.path) {
            const isActive = getActive(item.path, pathname, true);
            return (
              <Box
                key={item.label}
                onClick={
                  isActive
                    ? null
                    : () => {
                        dispatch(setArea(null));
                        dispatch(selectArea(null));
                        dispatch(setCameras([]));
                        dispatch(setLidars([]));
                        dispatch(setFences([]));
                        setTimeout(() => {
                          navigateOrOpenExternalLink(item.path, navigate);
                        }, 150);
                      }
                }
                className={`nav-link ${isActive ? "active" : ""}`}
              >
                {item.label}
              </Box>
            );
          }

          if (item.children && item.children.length > 0) {
            return <MenuPopover item={item} key={item.label} />;
          }

          return <Fragment key={item.label} />;
        })}
      </Box>
    </MenuWrapper>
  );
};

export default NavMenu;
