import { Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import { getActive, navigateOrOpenExternalLink } from "../../utils";
import { useDispatch } from "react-redux";
import { selectArea, setArea } from "../../toolkits/area/slice";
import { setCameras } from "../../toolkits/camera/slice";
import { setLidars } from "../../toolkits/lidar/slice";
import { setFences } from "../../toolkits/fence/slice";

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: none;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 103px;
    background-color: #18403ccd;
    margin-top: -1px;
  }
  .MuiMenuItem-root {
    color: #ffffffe5;
    font-size: 0.9rem;
    &:hover {
      color: #ffffff;
    }
    &.active {
      color: #ffffff;
      background-color: #18403cde;
      &:hover {
        background-color: #18403ccd;
      }
    }
  }
`;

const MenuPopover = ({ item }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMenuActive = item.children.find((it) =>
    getActive(it.path, pathname, true)
  );

  return (
    <Fragment key={item.label}>
      <Box
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`nav-link ${isMenuActive ? "active" : ""}`}
      >
        {item.label}
      </Box>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {item.children.map((floor) => {
          const isActive = getActive(floor.path, pathname, true);
          return (
            <MenuItem
              key={floor.label}
              onClick={() => {
                dispatch(setArea(null));
                dispatch(selectArea(null));
                dispatch(setCameras([]));
                dispatch(setLidars([]));
                dispatch(setFences([]));
                handleClose();
                setTimeout(() => {
                  navigateOrOpenExternalLink(floor.path, navigate);
                }, 150);
              }}
              className={`${isActive ? "active" : ""}`}
            >
              {floor.label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Fragment>
  );
};

export default MenuPopover;
