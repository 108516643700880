import CameraStream from "../CameraStream";

import "./AnnotationView.css";

const AnnotationView = ({ annotation, attached, videoWidth, videoHeight }) => {
  const renderContent = () => {
    if (annotation?.camUrl) {
      return (
        <CameraStream
          key={annotation.id}
          camera={annotation}
          videoWidth={videoWidth}
          videoHeight={videoHeight}
        />
      );
    }

    if (annotation?.cameraInfos && annotation?.cameraInfos[0]?.camUrl) {
      return (
        <CameraStream
          key={annotation.id}
          camera={annotation?.cameraInfos[0]}
          videoWidth={videoWidth}
          videoHeight={videoHeight}
        />
      );
    }

    if (annotation?.imageUrl) {
      return (
        <img
          src={annotation?.imageUrl}
          alt="annotation"
          style={{
            width: videoWidth,
            height: videoHeight,
          }}
        />
      );
    }
  };

  return annotation ? (
    <div
      className={`annotation ${attached ? "attached" : ""}`}
      style={{ width: `${videoWidth}px` }}
    >
      <div className="card" style={{ minHeight: `${videoHeight}px` }}>
        {renderContent(annotation)}
      </div>
      <div className="description">{annotation.description}</div>
    </div>
  ) : null;
};

export default AnnotationView;
