import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { findPeopleInArea } from "../../services/area.service";
import alertSlice from "../../toolkits/alert/slice";
import CardCustomWithSearch from "../Card/CardCustomWithSearch";
import { renderMenuChildren } from "../MenuList/util";
import { focusArea } from "../../toolkits/area/slice";

const SearchPeople = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);

  const handleMouseEnter = useCallback(
    (item) => {
      dispatch(
        focusArea({
          ...item,
          onMouseEnter: undefined,
          onMouseLeave: undefined,
        })
      );
    },
    [dispatch]
  );

  const handleMouseLeave = useCallback(() => {
    dispatch(focusArea(null));
  }, [dispatch]);

  useEffect(() => {
    if (keyword) {
      findPeopleInArea(keyword)
        .then((resp) => {
          setData(
            (resp?.data?.data ?? []).map((it) => ({
              ...it,
              type: "item",
              title: it.areaName,
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
            }))
          );
        })
        .catch((error) => {
          console.log({ error });
          dispatch(
            alertSlice.actions.error("Lỗi xảy ra khi tìm kiếm nhân viên")
          );
          setData([]);
        });
    } else {
      setData([]);
      dispatch(focusArea(null));
    }
  }, [keyword, handleMouseEnter, handleMouseLeave, dispatch]);

  return (
    <CardCustomWithSearch
      value={keyword}
      onChange={setKeyword}
      placeholder="Tìm kiếm nhân viên"
      cardSx={{
        marginTop: "12px",
        width: "100%",
      }}
      defaultExpanded={true}
    >
      {renderMenuChildren(data)}
    </CardCustomWithSearch>
  );
};

export default SearchPeople;
