import mqtt from "mqtt";
import { generateId } from "../../utils";

export var client;
export var connectStatus;

const host = process.env.REACT_APP_MQTT_URL;
// console.log(`emqx_${generateId()}`);
const options = {
  clean: true,
  connectTimeout: 3 * 1000,
  // protocolId: "MQIsdp",
  // protocolVersion: 3,
  // Authentication
  clientId: `emqx_ssb_${generateId()}`,
  username: process.env.REACT_APP_MQTT_USERNAME,
  password: process.env.REACT_APP_MQTT_PW,
};

// console.log(options)

export const mqttConnect = () => {
  connectStatus = "Connecting";
  client = mqtt.connect(host, options);
};

export const mqttDisconnect = () => {
  if (client) {
    client.end(() => {
      connectStatus = "Connect";
    });
  }
};

export const mqttSub = (topic) => {
  if (client) {
    client.subscribe(topic, (error) => {
      if (error) {
        console.log("Subscribe to topics error", error);
        return;
      }
    });
  }
};

export const mqttUnSub = (topic) => {
  if (client) {
    client.unsubscribe(topic, (error) => {
      if (error) {
        console.log("Unsubscribe error", error);
        return;
      }
    });
  }
};
