import React from "react"
import PropTypes from 'prop-types';
import { StyledTableCell } from "./table.style";
import { TableSortLabel , TableHead, Box, TableRow, Typography} from "@mui/material";
import { visuallyHidden } from '@mui/utils';

const CamAiTableHeadCell = [
    { id: 'id', label: "#", width: "4%", position: "center", sorted: true },
    { id: 'severity', label: "Mức độ", width: "8%", position: "center", sorted: true },
    { id: 'areaName', label: "Khu vực", width: "", position: "center", sorted: true },
    { id: 'deviceName', label: "Vị trí cảnh báo", width: "", position: "center", sorted: true },
    { id: 'eventType', label: "Loại sự kiện", width: "", position: "center", sorted: true },
    { id: 'description', label: "Mô tả", width: "", position: "center", sorted: false },
    { id: 'timeOccur', label: "Thời điểm", width: "", position: "center", sorted: true },
    { id: 'image', label: "Hình ảnh", width: "10%", position: "center", sorted: false },
    { id: 'status', label: "Trạng Thái", width: "10%", position: "center", sorted: true },
    { id: 'action', label: "Hành động", width: "10%", position: "center", sorted: false },
]

export const CamAiTableHead = (props) => {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {CamAiTableHeadCell.map((headCell) => (
                    headCell.sorted
                    ? <StyledTableCell key={headCell.id} align={headCell.position} width={headCell.width}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon
                        >
                            <Typography sx={{color: "#fff", fontSize: 14,}}>{headCell.label}</Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={{...visuallyHidden, fontSize: 8}}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                    : <StyledTableCell key={headCell.id} align={headCell.position} width={headCell.width}>
                        {headCell.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

CamAiTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};