import { Environment, OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useContext } from "react";

import Annotations from "../../components/Annotations";
import { Model } from "../../components/Model/Floor_00";
import ThreeLoader from "../../components/ThreeLoader";
import TweenUpdate from "../../components/TweenUpdate";
import usePrepareAreaData from "../../hooks/usePrepareAreaData";
import LayoutContext from "../../layouts/LayoutContext";
import EnvCity from "../../assets/potsdamer_platz_1k.hdr"

export default function Floor_00({ areaCode }) {
  const { controlsRef } = useContext(LayoutContext);

  usePrepareAreaData({ areaCode });

  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      camera={{ fov: 50, position: [-14, 1970, 1866] }}
      style={{ zIndex: 0 }}
    >
      <OrbitControls
        ref={controlsRef}
        // autoRotate
        // minAzimuthAngle={-Math.PI / 4}
        // maxAzimuthAngle={Math.PI / 4}
        minPolarAngle={0}
        maxPolarAngle={Math.PI - Math.PI / 2}
      />
      <Suspense fallback={<ThreeLoader />}>
        <Environment files={EnvCity} background blur={0.65} />
        <Stage
          controls={controlsRef}
          environment={{files: EnvCity}}
          intensity={1}
          shadows={false}
        >
          <Model controls={controlsRef}/>
        </Stage>
        <Annotations controls={controlsRef} />
        <TweenUpdate />
      </Suspense>
    </Canvas>
  );
}
