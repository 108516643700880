import { useThree } from "@react-three/fiber";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectArea } from "../toolkits/area/slice";
import {
  changeCameraPosition,
  fixScenceName,
  mappingArea2Model,
  mappingFences2Model,
} from "../utils";
import { setCameras } from "../toolkits/camera/slice";
import { setLidars } from "../toolkits/lidar/slice";
import { setFences } from "../toolkits/fence/slice";

const useSelectArea = () => {
  const { camera } = useThree();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedArea } = useSelector((state) => state.areas);

  const handleSelect = useCallback(
    (a) => {
      const area = mappingArea2Model(a?.name, pathname);
      const fence = mappingFences2Model(a?.name);

      if (area) {
        if (pathname === "/") {
          dispatch(selectArea(area));
          dispatch(setCameras([]));
          dispatch(setLidars([]));
          dispatch(setFences([]));
          setTimeout(() => {
            navigate(area.code);
          }, 150);
        } else {
          setTimeout(() => {
            if (
              selectedArea &&
              selectedArea.model === fixScenceName(a.name, pathname)
            ) {
              dispatch(selectArea(null));
            } else {
              dispatch(selectArea(area));
            }
          }, 150);
        }
      }

      if (fence && fence.camPos?.length) {
        changeCameraPosition(camera, fence.camPos);
      }
    },
    [camera, dispatch, navigate, pathname, selectedArea]
  );

  return { handleSelect };
};

export default useSelectArea;
