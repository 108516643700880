import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createHashRouter,
} from "react-router-dom";

import Layouts from "../layouts";
import Error404 from "../screens/errorpage/Error";
import Events from "../screens/events/Events";
import Floor00 from "../screens/floors/Floor_00";
import Floor01 from "../screens/floors/Floor_01";
import Floor02 from "../screens/floors/Floor_02";
import Floor03 from "../screens/floors/Floor_03";
import Floor04 from "../screens/floors/Floor_04";
import Floor05to10 from "../screens/floors/Floor_05to10";
import Floor11 from "../screens/floors/Floor_11";
import Floor12 from "../screens/floors/Floor_12";
import Floor13 from "../screens/floors/Floor_13";
import HomeScreen from "../screens/home/HomeScreen";
import Login from "../screens/login/login.screen";
import {
  DEFAULT_AREA_CODE_HANG_RAO,
  buildAreaCode,
  buildFloorKeys,
  buildFloorPath,
  hasAdmin,
  hasSecurityDepartment,
} from "../utils";

function GuestGuard({ children }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  if (isLoggedIn) {
    return <Navigate to={"/"} />;
  }
  return <>{children}</>;
}

function ProtectedRoute({ isAllowed, redirectTo = "/login", children }) {
  if (!isAllowed) {
    return <Navigate to={redirectTo} />;
  }
  return children ? children : <Outlet />;
}

function MainRoutes() {
  const { isLoggedIn, role } = useSelector((state) => state.auth);
  const floorKeys = buildFloorKeys();

  return (
    <Routes>
      <Route path="*" element={<Error404 />} />
      <Route
        path="/login"
        element={
          <GuestGuard>
            <Login />
          </GuestGuard>
        }
      />

      <Route element={<ProtectedRoute isAllowed={Boolean(isLoggedIn)} />}>
        <Route path="/" element={<Layouts />}>
          <Route
            path="/"
            element={<HomeScreen areaCode={DEFAULT_AREA_CODE_HANG_RAO} />}
          />

          {floorKeys.map((formatFloor) => {
            const floorPath = buildFloorPath(formatFloor);
            const areaCode = buildAreaCode(formatFloor);
            return (
              <Route
                key={formatFloor}
                path={floorPath}
                element={
                  <ProtectedRoute
                    isAllowed={
                      Boolean(isLoggedIn) &&
                      (hasAdmin(role) || hasSecurityDepartment(role))
                    }
                    redirectTo="/"
                  >
                    {formatFloor === "0" ? (
                      <Floor00 areaCode={areaCode} />
                    ) : formatFloor === "1" ? (
                      <Floor01 areaCode={areaCode} />
                    ) : formatFloor === "2" ? (
                      <Floor02 areaCode={areaCode} />
                    ) : formatFloor === "3" ? (
                      <Floor03 areaCode={areaCode} />
                    ) : formatFloor === "4" ? (
                      <Floor04 areaCode={areaCode} />
                    ) : formatFloor === "11" ? (
                      <Floor11 areaCode={areaCode} />
                    ) : formatFloor === "12" ? (
                      <Floor12 areaCode={areaCode} />
                    ) : formatFloor === "13" ? (
                      <Floor13 areaCode={areaCode} />
                    ) : (
                      <Floor05to10 areaCode={areaCode} />
                    )}
                  </ProtectedRoute>
                }
              />
            );
          })}

          <Route
            path="/events"
            element={
              <ProtectedRoute
                isAllowed={
                  Boolean(isLoggedIn) &&
                  (hasAdmin(role) || hasSecurityDepartment(role))
                }
                redirectTo="/"
              >
                <Events />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export const router = createHashRouter([
  {
    path: "*",
    Component: MainRoutes,
    errorElement: <Error404 />,
  },
]);

// export const router = createBrowserRouter([
//   {
//     path: "login",
//     element: <Container />,
//   },
//   {
//     path: "/",
//     element: <Layouts />,
//     errorElement: <Container />,
//     children: [
//       {
//         path: "/",
//         element: <Home annotationsKey="building-t1" />,
//       },
//       {
//         path: "floor-03",
//         element: <Floor03 annotationsKey="floor-03" />,
//       },
//       {
//         path: "floor-04",
//         element: <Floor04to11 annotationsKey="floor-04" />,
//       },
//       {
//         path: "floor-05",
//         element: <Floor04to11 annotationsKey="floor-05" />,
//       },
//       {
//         path: "floor-06",
//         element: <Floor04to11 annotationsKey="floor-06" />,
//       },
//       {
//         path: "floor-07",
//         element: <Floor04to11 annotationsKey="floor-07" />,
//       },
//       {
//         path: "floor-08",
//         element: <Floor04to11 annotationsKey="floor-08" />,
//       },
//       {
//         path: "floor-09",
//         element: <Floor04to11 annotationsKey="floor-09" />,
//       },
//       {
//         path: "floor-10",
//         element: <Floor04to11 annotationsKey="floor-10" />,
//       },
//       {
//         path: "floor-11",
//         element: <Floor04to11 annotationsKey="floor-11" />,
//       },
//       {
//         path: "events",
//         element: <Events />,
//       },
//     ],
//   },
// ]);
