import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import * as React from "react";
import { IconShieldLock } from "@tabler/icons-react";

const ToastMessage = ({ message = "", icon = "", color = "#FF4D4F", onClose }) => {
  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: +50, opacity: 0 }}
      layout
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.1)",
            // m: 0.25,
            border: 1,
            borderRadius: "50%",
            width: "3rem",
            height: "3rem",
            borderColor: `${color}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${color}`,
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconShieldLock stroke={2} size="1.5rem" color="#fff" />
          </Box>
        </Box>

        <Box
          component="div"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.1)",
            border: 2,
            maxWidth: "80%",
            display: "flex",
            alignItems: "center",
            // width: '3rem',
            borderRadius: "2rem",
            height: "2rem",
            borderColor: `${color}`,
            color: `${color}`,
            px: "1rem",
            // my: 2
          }}
        >
          <Typography
            sx={{
              width: "100%",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              fontFamily: "roboto",
              fontWeight: 500,
            }}
            variant="body1"
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </motion.div>
  );
};

export default ToastMessage;
