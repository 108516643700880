import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconCalendarClock, IconX } from "@tabler/icons-react"
import { viVN } from '@mui/x-date-pickers/locales';
import { useDispatch, useSelector } from "react-redux";
import eventSlice from "../../toolkits/event/slice"
import { addOneDay, date2Timestamp } from "../../utils";


const ButtonField = ((props) => {
    const {
        setOpen,
        setValue,
        deleted,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    return (
        <Button
            variant="contained"
            color="ochre"
            id={id}
            disabled={disabled}
            ref={ref}
            size="small"
            aria-label={ariaLabel}
            startIcon={<IconCalendarClock size={"1.2rem"} />}
            endIcon={label ? <IconX /> : null}
            onClick={() => label ? setValue?.(null) & deleted?.() : setOpen?.((prev) => !prev)}
            sx={{
                color: "white"
            }}
        >
            {label ? `${label}` : "Chọn ngày"}
        </Button>
    );
});

const ButtonDateRangePicker = (({selected, deleted}, props) => {
    const { eventMode } = useSelector((state) => state.events);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(null)
    }, [eventMode])

    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen, setValue, deleted } }}
            label={
                value === null
                    ? null
                    : value.format("DD/MM/YYYY")
            }
            value={value}
            disableFuture
            onChange={(newValue) => { setValue(newValue); selected(newValue)}}
            open={open}
            onClose={() => { setOpen(false) }}
            onOpen={() => setOpen(true)}
        />
    );
});

const DateRangePickerWithButtonField = () => {
    const { eventMode } = useSelector((state) => state.events);
    const dispatch = useDispatch();

    const selectedStart = (value) => {
        let dto = {}
        if (eventMode === "CAM_AI") {
            if (value !== null) Object.assign(dto, { startTime: date2Timestamp(value) })
            dispatch(eventSlice.actions.addFilterCamAi(dto))
        }
        else if (eventMode === "DEVICE_LOGS") {
            if (value !== null) Object.assign(dto, { eventFrom: value.toISOString() })
            dispatch(eventSlice.actions.addFilterDeviceLog(dto))
        }
    }

    const selectedEnd = (value) => {
        let dto = {}
        if (eventMode === "CAM_AI") {
            if (value !== null) Object.assign(dto, { endTime: date2Timestamp(addOneDay(value)) })
            dispatch(eventSlice.actions.addFilterCamAi(dto))
        }
        else if (eventMode === "DEVICE_LOGS") {
            if (value !== null) Object.assign(dto, { eventTo: addOneDay(value).toISOString() })
            dispatch(eventSlice.actions.addFilterDeviceLog(dto))
        }
    }

    const deleteStart = () => {
        if (eventMode === "CAM_AI") dispatch(eventSlice.actions.removeFilterCamAI({ startTime: ""}))
        else if (eventMode === "DEVICE_LOGS") dispatch(eventSlice.actions.removeFilterDeviceLog({}))
    }

    const deleteEnd = () => {
        if (eventMode === "CAM_AI") dispatch(eventSlice.actions.removeFilterCamAI({ endTime: ""}))
        else if (eventMode === "DEVICE_LOGS") dispatch(eventSlice.actions.removeFilterDeviceLog({}))
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={viVN.components.MuiLocalizationProvider.defaultProps.localeText}>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <ButtonDateRangePicker selected={selectedStart} deleted={deleteStart}/>
                <Typography sx={{ color: "white", px: 0.5 }}>-</Typography>
                <ButtonDateRangePicker selected={selectedEnd} deleted={deleteEnd}/>
            </Stack>

        </LocalizationProvider>
    );
}

export default DateRangePickerWithButtonField