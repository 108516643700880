import CardMedia from "@mui/material/CardMedia";
import JSMpeg from "@seydx/jsmpeg/lib/index.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import JSMpegWritableSource from "../../commons/jsmpeg-source.js";
import { socket } from "../../commons/socket-instance.js";
import {
  checkEnableStream,
  drawBlackScreenToCanvas,
} from "../../utils/index.jsx";
import "./index.css";

const DEFAULT_TIMEOUT = 60 * 1000;
const DEFAULT_BUFFER_SIZE = 1024 * 1024;
const DEFAULT_DELAY_START = 500;

const DEFAULT_EVENT_JOIN_STREAM = "join_stream";
const DEFAULT_EVENT_LEAVE_STREAM = "leave_stream";
const DEFAULT_CANVAS_ID = "myCanvas";

export default function CameraStream({
  camera,
  isSnap,
  videoWidth,
  videoHeight,
}) {
  const deviceName = camera?.deviceName ?? camera?.name;
  const deviceStatus = camera?.status;
  const deviceUrl = camera?.camUrl;

  const [imgSource] = useState(
    "http://media4.giphy.com/media/xTk9ZvMnbIiIew7IpW/giphy.gif"
  );

  const [isloading, setIsLoading] = useState(true);

  const snapshotTimerTimeout = useRef(null);
  const offline = useRef(false);
  const player = useRef(null);
  const streamTimeout = useRef(null);
  const delayStart = useRef(null);
  const loading = useRef(true);
  const play = useRef(false);

  useEffect(() => {
    const startSnapshot = async () => {
      console.log("********** start snap shot *******", deviceName);

      if (snapshotTimerTimeout.current) {
        clearInterval(snapshotTimerTimeout.current);
        snapshotTimerTimeout.current = null;
      }

      try {
        //TODO: change later
        //const isAvailable = deviceStatus === "online";
        const isAvailable = true;

        if (isAvailable) {
          //TODO: enable later
          // const snapshot = await getCameraSnapshot(camera._id, "?buffer=true");
          // loading.current = false;
          // if (!snapshot.data || (snapshot.data && snapshot.data === "")) {
          //   offline.current = true;
          // } else {
          //   offline.current = false;
          //   setImgSource(`data:image/png;base64,${snapshot.data}`);
          // }
        } else {
          offline.current = true;
          toast.error(`${deviceName}: 'offline'`);
        }
      } catch (err) {
        console.log(deviceName, err);
        toast.error(`${deviceName}: ${err.message}`);
        offline.current = true;
      }
    };

    if (isSnap && deviceUrl) {
      startSnapshot();
    }
  }, [deviceUrl, deviceName, isSnap]);

  function writeStream(buffer) {
    if (player.current) {
      player.current.source.write(buffer);
    }
  }

  const stopStream = useCallback(() => {
    console.log("********** stop stream *******", deviceName);

    if (player.current) {
      player.current.destroy();
      player.current = null;
    }

    if (streamTimeout.current) {
      clearTimeout(streamTimeout.current);
      streamTimeout.current = null;
    }

    socket.emit(DEFAULT_EVENT_LEAVE_STREAM, {
      feed: deviceName,
    });
  }, [deviceName]);

  const startStream = useCallback(async () => {
    console.log("********** start stream *******", deviceName);

    try {
      const isEnableStream = checkEnableStream(deviceStatus, deviceUrl);

      if (isEnableStream) {
        offline.current = false;
        const canvas = document.getElementById(DEFAULT_CANVAS_ID);

        player.current = new JSMpeg.Player(null, {
          source: JSMpegWritableSource,
          canvas: canvas,
          audio: true,
          //disableWebAssembly: true,
          autoSetWrapperSize: false,
          pauseWhenHidden: false,
          videoBufferSize: DEFAULT_BUFFER_SIZE,
          onSourcePaused: () => {
            play.current = false;
          },
          onSourceEstablished: () => {
            setIsLoading(false);
            loading.current = false;
            offline.current = false;
            play.current = true;

            if (streamTimeout.current) {
              clearTimeout(streamTimeout.current);
              streamTimeout.current = null;
            }
          },
        });

        player.current.volume = 0;
        player.current.name = deviceName;

        socket.emit(DEFAULT_EVENT_JOIN_STREAM, {
          feed: deviceName,
        });

        socket.on(deviceName, writeStream);

        streamTimeout.current = setTimeout(() => {
          if (loading.current) {
            setIsLoading(false);
            loading.current = false;
            offline.current = true;

            stopStream();
            toast.warning(`${deviceName}: 'timeout'`);
          }
        }, DEFAULT_TIMEOUT);
      } else {
        stopStream();

        offline.current = true;
        loading.current = false;
        setIsLoading(false);
        toast.error(`${deviceName}: 'offline'`);
        drawBlackScreenToCanvas(DEFAULT_CANVAS_ID);
      }
    } catch (err) {
      stopStream();

      console.log(deviceName, err);
      toast.error(`${deviceName}: ${err.message}`);

      setIsLoading(false);
      loading.current = false;
      offline.current = true;
      drawBlackScreenToCanvas(DEFAULT_CANVAS_ID);
    }
  }, [deviceName, deviceStatus, deviceUrl, stopStream]);

  useEffect(() => {
    if (!isSnap && camera) {
      if (delayStart.current) {
        clearTimeout(delayStart.current);
      }
      delayStart.current = setTimeout(() => {
        startStream();
      }, DEFAULT_DELAY_START);
    }
  }, [camera, isSnap, startStream]);

  useEffect(() => {
    return () => {
      console.log("destroy camera view");
      stopStream();
    };
  }, [stopStream]);

  return (
    <>
      {isSnap ? (
        <div className="video-wrapper">
          <CardMedia
            component="img"
            image={imgSource}
            alt="green iguana"
            sx={{
              border: "1px solid blue",
              width: `${videoWidth}px`,
              height: `${videoHeight}px`,
            }}
          />
        </div>
      ) : (
        <div className="video-wrapper">
          <div className={isloading ? "lds-dual-ring" : ""} />
          <canvas
            id={DEFAULT_CANVAS_ID}
            className="video-wrapper__canvas"
            style={{
              border: "1px solid blue",
              width: `${videoWidth}px`,
              height: `${videoHeight}px`,
            }}
          />
        </div>
      )}
    </>
  );
}
