import { createSlice } from "@reduxjs/toolkit";

import { replaceitemInList } from "../../utils";

const initialState = {
  errorMassage: false,
  isLoading: false,
  lidars: [],
  lidarDto: {
    // page: 1,
    // limit: 100,
    // keyword: "",
  },
};

const reducer = createSlice({
  name: "lidars",
  initialState,
  reducers: {
    getLidars: (state, action) => {
      state.lidars = [];
      state.lidarDto = action.payload;
    },
    setLidars: (state, action) => {
      state.lidars = action.payload;
    },
    updateLidar: () => {},
    updateLidarSuccess: (state, action) => {
      const lidar = action.payload ?? {};
      state.lidars = replaceitemInList(state.lidars, lidar);
    },
  },
});

export const { getLidars, setLidars, updateLidar, updateLidarSuccess } =
  reducer.actions;

export default reducer;
