import { Html, useProgress } from "@react-three/drei";

export default function ThreeLoader() {
  const { progress } = useProgress();
  
  return (
    <Html center style={{ minWidth: 200 }}>
      Đã tải {`${Math.floor(progress)}%`}
    </Html>
  );
}
