import { useGLTF } from "@react-three/drei";
import React from "react";

import useModelHandler from "../../hooks/useModelHandler";
import { BaseModel } from "./BaseModel";

const MODEL_URL = "./glb/Floor_11.glb";

export const Model = ({ controls, ...props }) => {
  const { scene } = useGLTF(MODEL_URL);
  const { onPointerUp, onDoubleClickFloor } = useModelHandler(controls);

  return (
    <BaseModel
      isSplitted={false}
      scenes={scene}
      onPointerUp={onPointerUp}
      onDoubleClick={onDoubleClickFloor}
      {...props}
    />
  );
};

useGLTF.preload(MODEL_URL, "./draco/");
