import { createSlice } from "@reduxjs/toolkit";
import { getLocalData, setLocalData } from "../../services/localStorage";
import { DEFAULT_OPEN_LEVEL_MD, DEFAULT_PAGE_SIZE } from "../../utils";

const initialState = {
  err: false,
  isLoadingEvents: false,
  isShowEventDetailModeModal: false,
  events: [],
  selectedEvent: {
    id: 0,
    timeOccur: 0, //1700140150938000,
    notificationStatus: 0,
    eventType: "",
    eventName: "",
    deviceId: "",
    deviceName: "",
    areaId: "",
    areaName: "",
    rawMsg: "",
    // {
    //     description:"",
    //     eventType:"",
    //     image: "",
    //     imageResult: "",
    //     imageZoomResult: "",
    //     bboxes:[[]],
    //     timeStart: 0,
    //     timeEnd: 0,
    //     severity:"",
    //     additionalInformation:null,
    //     camera_id:""
    // },
    status: "",
    aiImages: [],
    histories: [],
    // [
    //             "http://192.168.1.220:9000/test/20231116/object_pe_ff004fcb-47fe-4298-b5f7-1ca34ad01c1e_736200_1700140150961.jpg",
    //             "http://192.168.1.220:9000/test/20231116/object_pe_ff004fcb-47fe-4298-b5f7-1ca34ad01c1e_736200_1700140150961.jpg"
    //  ],
    severity: "",
    filterCamAI: "",
    areaRestrictInfos: [],
  },
  selectedEventDeviceLog: {
    accessCode: "",
    type: "",
    description: "",
    code: 4864,
    subCode: 3,
    data: "",
    timestamp: "", //"2023-08-15T02:58:40Z",
    userAccess: {},
    // {
    //   id: "44e0592d-6552-47da-92f1-59ff4d6adfee",
    //   fullName: "QuânNV2",
    //   userType: "STAFF",
    //   accessCode: "10013",
    //   availableAt: null,
    //   expiredAt: null,
    //   enableFaceAuthentication: true,
    //   enableFingerAuthentication: true,
    //   enableCardAuthentication: true,
    //   createdAt: "2023-03-23T07:38:56.842501Z",
    //   updatedAt: "2023-04-10T07:07:59.694810Z"
    // },
    area: {},
    // {
    //   id: "fcbf5b4e-fc32-41dc-b39a-3795b346941b",
    //   name: "Phòng Họp",
    //   code: "PH",
    //   description: null
    // }
  },
  count: 0,
  pageNumber: 1,
  totalPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  keyword: "",
  mode: 0,
  deviceLogFilter: {},
  // eventType: "",
  // eventFrom:"",
  // eventTo: "",
  // accessCode: "",
  // deviceCode: "",
  // orderBy: "",
  // descBy: ""
  eventMode: "CAM_AI", // "CAM_AI" + "DEVICE_LOGS"
  openLevel: DEFAULT_OPEN_LEVEL_MD,
  camAiFilter: {},
  muteAudio: getLocalData("muteAudio") ? getLocalData("muteAudio") : false,
  notifyCamAi: {
    count: 0,
    countAllow: 0,
    countNew: 0,
  },
  cameraMqtt: getLocalData("cameraMqtt") ? getLocalData("cameraMqtt"): [],
  lidarMqtt: getLocalData("lidarMqtt") ? getLocalData("lidarMqtt"): [],
  fenceMqtt: getLocalData("fenceMqtt") ? getLocalData("fenceMqtt"): [],
  eventTypes: [],
  doorStatus: [],
  devices: []
};

const reducer = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEventList: (state, action) => {},
    addFilterCamAi: (state, action) => {
      state.camAiFilter = Object.assign({}, state.camAiFilter, action.payload);
      state.count = 0;
      state.pageNumber = 1;
      state.totalPage = 1;
    },
    removeFilterCamAI: (state, action) => {
      const filter = { ...state.camAiFilter };
      Object.keys(action.payload).map((key) => {
        delete filter[key];
        return null;
      });
      state.camAiFilter = { ...filter };
      state.count = 0;
      state.pageNumber = 1;
      state.totalPage = 1;
    },
    addFilterDeviceLog: (state, action) => {
      state.deviceLogFilter = Object.assign(
        {},
        state.deviceLogFilter,
        action.payload
      );
    },
    removeFilterDeviceLog: (state, action) => {
      state.deviceLogFilter = {};
      state.count = 0;
      state.pageNumber = 1;
      state.totalPage = 1;
    },
    getEventCamAIWithFilter: (state, action) => {
      state.isLoadingEvents = true;
    },
    addEventMQTT: (state, action) => {
      const event = action.payload;
      state.isLoadingEvents = false;
      state.events = [
        {
          id: 0,
          timeOccur: event.timeStart * 1000, //1700140150938000,
          notificationStatus: 0,
          eventType: event.eventType,
          eventName: "",
          deviceId: event.camera_id,
          deviceName: "",
          areaId: "",
          areaName: "",
          rawMsg: JSON.stringify(event),
          status: "",
          aiImages: [event.image],
          severity: event.severity,
        },
        ...state.events,
      ].slice(0, DEFAULT_PAGE_SIZE);
    },
    getEventSuccess: (state, action) => {
      state.isLoadingEvents = false;
      state.count = action.payload.count;
      state.totalPage = action.payload.totalPage;
      state.pageNumber !== state.totalPage && state.totalPage !== 0
        ? (state.events = [...action.payload.rows, ...state.events].slice(
            0,
            state.pageSize
          ))
        : (state.events = action.payload.rows);
    },
    getEventDeviceLogs: (state, action) => {
      state.events = [];
      state.isLoadingEvents = true;
    },
    getEventDeviceLogsSuccess: (state, action) => {
      state.isLoadingEvents = false;
      state.count = action.payload.totalElements;
      state.totalPage = action.payload.totalPages;
      state.pageNumber !== state.totalPage
        ? (state.events = [...action.payload.content, ...state.events].slice(
            0,
            state.pageSize
          ))
        : (state.events = action.payload.content);

      // console.log(state.events)
    },
    switchModeEvent: (state, action) => {
      state.events = [];
      state.camAiFilter = {};
      state.deviceLogFilter = {};
      state.count = 0;
      state.pageNumber = 1;
      state.totalPage = 1;
      state.isLoadingEvents = true;
      state.eventMode = action.payload;
    },
    eventTablePageChange: (state, action) => {
      state.isLoadingEvents = true;
      state.pageNumber = action.payload;
    },
    showEventDetailModal: (state, action) => {
      state.isShowEventDetailModeModal = true;
    },
    hideEventDetailModal: (state, action) => {
      state.isShowEventDetailModeModal = false;
    },
    getEventError: (state, action) => {
      state.events = [];
    },
    alertEvent: (state, action) => {},
    setOpenLevel: (state, action) => {
      state.openLevel = action.payload;
    },
    updateStatusEvent: (state, action) => {},
    updateStatusEventCamAi: (state, action) => {},
    getNotifyEventCamAI: (state, action) => {},
    getNotifyEventCamAISuccess: (state, action) => {
      state.notifyCamAi = Object.assign({}, state.notifyCamAi, action.payload);
    },
    changeMute: (state, action) => {
      state.muteAudio = !state.muteAudio;
      setLocalData("muteAudio", state.muteAudio);
    },
    addCameraMqtt: (state, action) => {
      state.cameraMqtt = [action.payload, ...state.cameraMqtt].slice(0, state.pageSize)
    },
    addLidarMqtt: (state, action) => {
      state.lidarMqtt = [action.payload, ...state.lidarMqtt].slice(0, state.pageSize)
    },
    addFenceMqtt: (state, action) => {
      state.fenceMqtt = [action.payload, ...state.fenceMqtt].slice(0, state.pageSize)
    },
    changePageSize: (state, action) => {
      state.pageSize = action.payload
      state.pageNumber = 1
    },
    getEventTypes: (state, action) => {},
    getEventTypesSuccess: (state, action) => {
      state.eventTypes = action.payload
    },
    getDevices: (state, action) => {},
    getDevicesSuccess: (state, action) => {
      state.devices = action.payload
    },
    getDoorStatus: (state,action) => {},
    getDoorStatusSuccess: (state,action) => {
        state.doorStatus = action.payload
    }
  },
});

export const { setOpenLevel } = reducer.actions;

export default reducer;
