import { styled } from "styled-components";

export const NavBarWrapper = styled.div`
  --nav-height: 2.75rem;

  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const NavBar = styled.div`
  background-color: #18403c;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  height: var(--nav-height);
  overflow: hidden;
`;

export const NavMain = styled.div`
  display: flex;
  height: var(--nav-height);
  flex-grow: 1;

  .nav-link {
    padding: 0 2rem;
    text-transform: uppercase;
    color: #ffffffcc;
    border-style: solid;
    border-width: thin;
    border-color: #ffffff1f;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    font-size: 0.85rem;
    transition: all 0.2s;

    &.active {
      color: #ffffff;
      border-color: transparent;
      background-color: #ffffff1f;
      cursor: default;
    }
    &:hover {
      color: #ffffff;
    }
  }
`;

export const NavSpan = styled.div`
  background: #1e2757;
  padding: 0 3.75em;
  position: relative;
  right: 1em;
  margin-right: auto;
  transform: skew(45deg);

  &:hover {
    animation-name: logo-hover;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.17, 0.57, 0.31, 0.85);
  }
`;

export const NavLogo = styled.div`
  padding: 0 1em;
  font-weight: 600;
  font-size: 1em;
  color: #fff;
  line-height: var(--nav-height);
  transform: skew(-45deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  line-height: var(--nav-height);
`;

export const ToolsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  right: 1.5em;
  height: auto;
`;
