import { TableRow, TableCell, Box, Fade, TableContainer, Table, Modal, TableBody } from "@mui/material"
import React from "react"
import { useSelector, useDispatch } from "react-redux";
import eventSlice from "../../toolkits/event/slice"

const EventDetailModal = () => {
    const dispatch = useDispatch()
    let { selectedEvent, isShowEventDetailModeModal } = useSelector(state => state.events)
    const onClose = () => {
        dispatch(eventSlice.actions.hideEventDetailModal());
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isShowEventDetailModeModal}
            onClose={onClose}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            sx={{
                position: "absolute",
                width: "40%",
                height: "80%",
                top: "10%",
                left: "25%",
                right: "auto",
                bottom: "auto",
                overflow: "auto"
            }}
        >
            <Fade in={isShowEventDetailModeModal}>
                <Box sx={{
                    // height: 400,
                    // width: "100%",
                    // height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "grey.300",
                    color: "white",
                    flexWrap: 'wrap',
                    overflow: "auto"

                }} flexDirection="column">
                    <Box
                        component="img"
                        sx={{
                            // height: "100%",
                            // height: ,
                            width: "80%",
                            p: 0.2,
                            // width: "40%",
                        }}
                        alt="The house from the offer."
                        src="http://www.shutterstock.com/image-vector/picture-icon-260nw-323592404.jpg"
                    />
                    <Box sx={{
                        width: "80%",
                        // height: windowHeight * 0.1,
                    }} display="flex" flexDirection="row">
                        <Box
                            component="img"
                            sx={{
                                // height: "100%",
                                width: "50%",
                                p: 0.2,

                                // width: "100%",
                                // maxHeight: { xs: 233, md: 167 },
                                // maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="The house from the offer."
                            src="http://www.shutterstock.com/image-vector/picture-icon-260nw-323592404.jpg"
                        />
                        <Box
                            component="img"
                            sx={{
                                // height: "100%",
                                width: "50%",
                                p: 0.2,
                                // maxHeight: { xs: 233, md: 167 },
                                // maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="The house from the offer."
                            src="http://www.shutterstock.com/image-vector/picture-icon-260nw-323592404.jpg"
                        />
                    </Box>

                    <Item item={selectedEvent} />
                    {/* </Box> */}
                </Box>
            </Fade>
        </Modal>
    )
}
const Item = ({ item }) => {
    return (
        <Box
            sx={{
                // boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                width: "90%",
                // bgcolor: 'transparent',
                color: 'grey.300',
                p: 1,
                m: 1,
                // border: 1,
                // borderColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 2,
                justifyContent: 'start',
                alignItems: "center",
                display: "flex",
                fontSize: '0.875rem',
                fontWeight: '700',
            }}
        >
            <TableContainer >
                <Table size="small" aria-label="customized  table">
                    <TableBody>
                        {/* {data.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell width="40%" component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.calories}</StyledTableCell>
                            </StyledTableRow>
                        ))} */}
                        {Object.keys(item).map(key => {
                            return (
                                <TableRow key={[key]}>
                                    <TableCell width="40%" component="th" scope="row">
                                        {key}
                                    </TableCell>
                                    <TableCell align="right">{item[key]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default EventDetailModal