import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  checkAreaHasEmergency,
  checkFenceHasEmergency,
  mappingArea2Model,
  mappingFences2Model,
} from "../utils";
import useEmergencyEvents from "./useEmergencyEvents";
import useLidarEmergencyEventsWithFence from "./useLidarEmergencyEventsWithFence";

const useCheckModelEmergency = (sceneName) => {
  const { pathname } = useLocation();
  const { events } = useSelector((state) => state.events);

  const emergencyEvents = useEmergencyEvents(events);
  const emergencyLidarEventsWithFence =
    useLidarEmergencyEventsWithFence(emergencyEvents);

  const matchingModelEmergency = useMemo(() => {
    const area = mappingArea2Model(sceneName, pathname);
    if (area) {
      const found = checkAreaHasEmergency(area.name, emergencyEvents);
      if (found) {
        return {
          matchType: "AREA",
          ...found,
        };
      }
    }

    const fence = mappingFences2Model(sceneName, pathname);
    if (fence) {
      const found = checkFenceHasEmergency(fence.name, emergencyEvents);
      if (found) {
        return {
          matchType: "FENCE",
          ...found,
        };
      }
    }
    if (fence) {
      const found = checkFenceHasEmergency(
        fence.code,
        emergencyLidarEventsWithFence
      );
      if (found) {
        return {
          matchType: "FENCE_BY_LIDAR",
          ...found,
        };
      }
    }

    return null;
  }, [sceneName, pathname, emergencyEvents, emergencyLidarEventsWithFence]);

  return matchingModelEmergency;
};

export default useCheckModelEmergency;
