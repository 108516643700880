import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaCode: "",
  areaDetail: null,
  areas: [],
  selectedArea: null,
  focusedArea: null,
};

const reducer = createSlice({
  name: "areas",
  initialState,
  reducers: {
    getArea: (state, action) => {
      state.areaDetail = null;
      state.areaCode = action.payload;
    },
    setArea: (state, action) => {
      state.areaDetail = action.payload;
    },
    getListAreas: (state, action) => {},
    getListAreasSuccess: (state, action) => {
      state.areas = action.payload;
    },
    selectArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    updateSelectArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    focusArea: (state, action) => {
      state.focusedArea = action.payload;
    },
  },
});

export const {
  getArea,
  setArea,
  getListAreas,
  getListAreasSuccess,
  selectArea,
  updateSelectArea,
  focusArea,
} = reducer.actions;

export default reducer;
