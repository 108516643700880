import { useMemo } from "react";

import {
  DEFAULT_SEVERITY_CRITICAL,
  DEFAULT_SEVERITY_HIGH,
  DEFAULT_STATUS_NEW,
} from "../utils";

const useEmergencyEvents = (events = []) => {
  const newHighEvents = useMemo(
    () =>
      events.filter(
        (event) =>
          (event.severity === DEFAULT_SEVERITY_HIGH ||
            event.severity === DEFAULT_SEVERITY_CRITICAL) &&
          event.status === DEFAULT_STATUS_NEW
      ),
    [events]
  );

  return newHighEvents;
};

export default useEmergencyEvents;
