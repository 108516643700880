import { all, call, put, takeEvery } from "redux-saga/effects";

import { getListFloor, updateFloorDetail } from "../../services/floor.service";
import alertSlice from "../alert/slice";
import { getFloors, setFloors, updateFloor, updateFloorSuccess } from "./slice";

function* getFloorsSaga({ payload }) {
  try {
    const response = yield call(getListFloor, payload);
    if (response.status === 200) {
      const respData = response.data;
      yield put(
        setFloors(
          respData?.data?.rows?.map((item) => ({
            ...item,
            camPos: item.floorPos,
          })) ?? []
        )
      );
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi tải danh sách cảnh báo tầng")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(
      alertSlice.actions.error("Lỗi xảy ra khi tải danh sách cảnh báo tầng")
    );
    console.log(error);
  }
}

function* updateFloorSaga({ payload }) {
  try {
    const response = yield call(updateFloorDetail, payload);

    if (response.status === 200) {
      yield put(
        alertSlice.actions.success("Cập nhật cảnh báo tầng thành công")
      );
      yield put(updateFloorSuccess(payload));
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi cập nhật cảnh báo tầng")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(
      alertSlice.actions.error("Lỗi xảy ra khi cập nhật cảnh báo tầng")
    );
    console.log(error);
  }
}

export default function* saga() {
  yield all([
    takeEvery(getFloors().type, getFloorsSaga),
    takeEvery(updateFloor().type, updateFloorSaga),
  ]);
}
