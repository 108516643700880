import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";

const FullSizeImage = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
});

const DialogImage = ({
  src,
  alt,
  isOpen,
  onClose,
  title,
  dialogProps = {},
  topContent,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "#18403cef",
          color: "#ffffffcc",
        },
      }}
      {...dialogProps}
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          {title ? <Typography fontWeight={600}>{title}</Typography> : <div />}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {topContent}
        {src && <FullSizeImage src={src} alt={alt} />}
      </DialogContent>
    </Dialog>
  );
};

export default DialogImage;
