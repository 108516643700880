import { DEFAULT_ANNOTATION_TYPE_CAMERA, getIconForAnnotation } from "../../utils";

const AnnotationConent = ({
  annotationType,
  isShowTitle,
  title,
  isEnableStream,
}) => {
  const Icon = getIconForAnnotation(annotationType);

  return (
    <>
      {isShowTitle && (
        <span
          style={{
            color: "#fff",
            backgroundColor: "rgba(0,0,0,.66)",
            padding: "6px 8px",
            borderRadius: "6px",
            position: "absolute",
            minWidth: 120,
            top: -40,
          }}
        >
          {title}
        </span>
      )}
      <span
        style={{
          color: isEnableStream ? "#7df342" : "#ffffffcc",
          fontSize: "0.35rem",
          paddingTop: annotationType === DEFAULT_ANNOTATION_TYPE_CAMERA ? 4 : 0,
        }}
      >
        {<Icon />}
      </span>
    </>
  );
};

export default AnnotationConent;
