// import { useGLTF } from "@react-three/drei";
// import React from "react";

// import useModelHandler from "../../hooks/useModelHandler";
// import { BaseModel } from "./BaseModel";

// const MODEL_URL = "./glb/BuildingT1.glb";

// export function Model(props) {
//   const { onPointerUp } = useModelHandler();

//   return (
//     <BaseModel modelUrl={MODEL_URL} onPointerUp={onPointerUp} {...props} />
//   );
// }

import { useGLTF } from "@react-three/drei";
import React from "react";

import useModelHandler from "../../hooks/useModelHandler";
import { BaseModel } from "./BaseModel";

const MODEL_URL = "./glb/BuildingT1_new-processed.glb";

export function Model({ controls, ...props }) {
  const { scene } = useGLTF(MODEL_URL);
  const { onPointerUp, onDoubleClickHome } = useModelHandler(controls);

  return (
    <BaseModel
      isSplitted={true}
      scenes={scene}
      onPointerUp={onPointerUp}
      onDoubleClick={onDoubleClickHome}
      {...props}
    />
  );
}

useGLTF.preload(MODEL_URL, "./draco/");
