import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./rootSaga";

import alertSlice from "./alert/slice";
import annotationSlice from "./annotation/slice";
import areaSlice from "./area/slice";
import authSlice from "./auth/slice";
import cameraSlice from "./camera/slice";
import eventSlice from "./event/slice";
import fenceSlice from "./fence/slice";
import lidarSlice from "./lidar/slice";
import floorSlice from "./floor/slice";
import deviceSlice from "./devices/slice"

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(sagaMiddleware);

export const store = configureStore({
  reducer: {
    areas: areaSlice.reducer,
    fences: fenceSlice.reducer,
    lidars: lidarSlice.reducer,
    floors: floorSlice.reducer,
    annotations: annotationSlice.reducer,
    cameras: cameraSlice.reducer,
    events: eventSlice.reducer,
    auth: authSlice.reducer,
    alert: alertSlice.reducer,
    devices: deviceSlice.reducer
  },
  middleware,
});

sagaMiddleware.run(rootSaga);
