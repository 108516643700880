import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

const EventModeButton = ({ text, enable, onClick }) => {
    return (
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
                color={enable ? "ochre" : "inherit"}
                size="small"
                // sx={{ color: "#18403c" }}
                onClick={!enable ? onClick: null}
                variant= {enable ? "contained": "outlined"}
            >
                <Typography color="#ffff" fontSize={12}>{text}</Typography>
            </Button>
        </div>
    );
};

export default EventModeButton;
