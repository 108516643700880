import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { IconLock, IconLockCancel, IconLockCheck } from "@tabler/icons-react";
import dayjs from "dayjs";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircleBlink from "../../../components/Circle/CircleBlink";
import ClickToShowFullImage from "../../../components/ClickToShowFullImage";
import eventSlice from "../../../toolkits/event/slice";
import {
  DEFAULT_STATUS_COMPLETED_SKIP,
  DEFAULT_STATUS_COMPLETED_VIOLATION,
  DEFAULT_STATUS_PROCESSING,
  date2passtime,
  findDevice,
  getColorWithSeverity,
  getComparator,
  getStatusColor,
  getStatusDoorColor,
  getStatusDoorLabel,
  getStatusLabel,
  getStatusRelayDoorLabel,
  getUserTypeLabel,
  normalizationLink,
  parseRawMsg,
  stableSort,
  timestamp2datetime,
} from "../../../utils";
import { CamAiTableHead } from "./HeaderTable";
import { StyledTableCell, StyledTableRow } from "./table.style";

export const CamAiTables = () => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [click, setClicked] = useState(1);

  const windowWidth = useRef(window.innerWidth);
  const { events, pageSize } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const changeStatusEventCamAI = (eventId, status) => {
    dispatch(
      eventSlice.actions.updateStatusEventCamAi({
        eventId: eventId,
        status: status,
      })
    );
  };

  const handleRequestSort = (event, property) => {
    if (click < 2 || orderBy !== property) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setClicked(orderBy === property ? click + 1 : 1);
    } else if (click === 2 && orderBy === property) {
      setOrder("desc");
      setOrderBy("id");
      setClicked(1);
    }
  };

  const eventsSorted = useMemo(
    () => stableSort(events, getComparator(order, orderBy)).slice(0, pageSize),
    [order, orderBy, pageSize, events]
  );

  return (
    <Table
      sx={{
        width: windowWidth.current,
        borderCollapse: "collapse",
      }}
      stickyHeader
      size="small"
      aria-label="customized table"
    >
      <CamAiTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {eventsSorted?.map((row) => {
          const parsedRawMsg = parseRawMsg(row.rawMsg);

          return (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="center" className={row.severity}>
                {row.id ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CircleBlink
                  weight={15}
                  color={getColorWithSeverity(row.severity)}
                />
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.areaName ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.deviceName ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.eventType}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {parsedRawMsg?.description ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Stack>
                  <span>{date2passtime(row.timeOccur) ?? ""}</span>
                  <span>{timestamp2datetime(row.timeOccur) ?? ""}</span>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" className={row.severity ?? ""}>
                <Stack direction="row" gap={1}>
                  {parsedRawMsg?.image && (
                    <ClickToShowFullImage
                      key={`${parsedRawMsg?.image}-0`}
                      thumbnailSrc={normalizationLink(parsedRawMsg?.image)}
                      fullSizeSrc={normalizationLink(parsedRawMsg?.image)}
                      alt={`img-0`}
                    />
                  )}
                  {parsedRawMsg?.imageResult && (
                    <ClickToShowFullImage
                      key={`${parsedRawMsg?.imageResult}-1`}
                      thumbnailSrc={normalizationLink(
                        parsedRawMsg?.imageResult
                      )}
                      fullSizeSrc={normalizationLink(parsedRawMsg?.imageResult)}
                      alt={`img-1`}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                <Box
                  component="span"
                  bgcolor="#2A3A35"
                  color={getStatusColor(row.status)}
                  sx={{ p: 1, borderRadius: 1 }}
                >
                  {getStatusLabel(row.status)}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center">
                  <Tooltip
                    title={getStatusLabel(DEFAULT_STATUS_PROCESSING)}
                    placement="top"
                  >
                    <IconButton
                      size="large"
                      aria-label="processing"
                      onClick={() =>
                        changeStatusEventCamAI(
                          row.id,
                          DEFAULT_STATUS_PROCESSING
                        )
                      }
                      color="inherit"
                    >
                      <IconLock stroke={1.5} size="1rem" color="#ffff" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={getStatusLabel(DEFAULT_STATUS_COMPLETED_SKIP)}
                    placement="top"
                  >
                    <IconButton
                      size="large"
                      aria-label="skip"
                      onClick={() =>
                        changeStatusEventCamAI(
                          row.id,
                          DEFAULT_STATUS_COMPLETED_SKIP
                        )
                      }
                      color="inherit"
                    >
                      <IconLockCheck stroke={1.5} size="1rem" color="#ffff" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={getStatusLabel(DEFAULT_STATUS_COMPLETED_VIOLATION)}
                    placement="top"
                  >
                    <IconButton
                      size="large"
                      aria-label="completed"
                      onClick={() =>
                        changeStatusEventCamAI(
                          row.id,
                          DEFAULT_STATUS_COMPLETED_VIOLATION
                        )
                      }
                      color="inherit"
                    >
                      <IconLockCancel stroke={1.5} size="1rem" color="#ffff" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
        {/* {emptyRows > 0 && (
          <tr style={{ height: (41 * emptyRows) }}>
            <td aria-hidden />
          </tr>
        )} */}
      </TableBody>
    </Table>
  );
};

export const DeviceLogTable = () => {
  const windowWidth = useRef(window.innerWidth);
  const { events, isLoadingEvents } = useSelector((state) => state.events);
  return (
    <Table
      sx={{
        width: windowWidth.current,
        // minWidth: windowWidth * 0.5,
        borderCollapse: "collapse",
      }}
      stickyHeader
      size="small"
      aria-label="customized table"
    >
      <TableHead>
        <TableRow>
          <StyledTableCell align="center" width={"5%"}>
            #
          </StyledTableCell>
          <StyledTableCell align="center">Mã thiết bị</StyledTableCell>
          <StyledTableCell align="center">Khu vực</StyledTableCell>
          <StyledTableCell align="center">Thời gian</StyledTableCell>
          <StyledTableCell align="center">Sự kiện</StyledTableCell>
          <StyledTableCell align="center">Người truy cập</StyledTableCell>
          <StyledTableCell align="center">Loại người dùng</StyledTableCell>
          <StyledTableCell align="center">Mã truy cập</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoadingEvents ? (
          events.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="center">{index + 1}</StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.deviceCode ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.area?.name ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.timestamp
                  ? dayjs(row.timestamp).format("DD/MM/YYYY HH:mm:ss")
                  : ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.description ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.userAccess == null ? "" : row.userAccess.fullName ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.userAccess == null
                  ? ""
                  : getUserTypeLabel(row.userAccess.userType) ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.accessCode ?? ""}
              </StyledTableCell>
            </StyledTableRow>
          ))
        ) : (
          <Loading col={6} />
        )}
      </TableBody>
    </Table>
  );
};

export const DoorStatusTable = () => {
  const windowWidth = useRef(window.innerWidth);
  let { devices, doorStatus } = useSelector((state) => state.events);
  const doorEvent = useMemo(() => {
    return doorStatus?.map((item) => {
      let device = findDevice(item.doorID, devices)[0];
      return {
        ...item,
        area: device?.area?.name,
        description: device?.description
      }
    })
  }, [devices, doorStatus])
  return (
    <Table
      sx={{
        width: windowWidth.current,
        // minWidth: windowWidth * 0.5,
        borderCollapse: "collapse",
      }}
      stickyHeader
      size="small"
      aria-label="customized table"
    >
      <TableHead>
        <TableRow>
          <StyledTableCell align="center" width={"5%"}>
            #
          </StyledTableCell>
          <StyledTableCell align="center">Tên thiết bị</StyledTableCell>
          <StyledTableCell align="center">Mã thiết bị</StyledTableCell>
          <StyledTableCell align="center">Loại thiết bị</StyledTableCell>
          <StyledTableCell align="center">Khu vực</StyledTableCell>
          <StyledTableCell align="center">Thời gian mở gần nhất</StyledTableCell>
          <StyledTableCell align="center">Trạng thái relay</StyledTableCell>
          <StyledTableCell align="center">Trạng thái cửa</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          doorEvent?.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="center">{index + 1}</StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.doorName ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.doorID ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.description ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                {row.area ?? ""}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.lastOpenTime
                  ? dayjs(row.lastOpenTime * 1000).format("DD/MM/YYYY HH:mm:ss")
                  : ""}
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
                <Box
                  component="span"
                  bgcolor="#2A3A35"
                  color={getStatusDoorColor(row.isUnlocked)}
                  sx={{ p: 1, borderRadius: 1 }}
                >
                  {getStatusRelayDoorLabel(row.isUnlocked)}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center" scope="row">
              <Box
                  component="span"
                  bgcolor="#2A3A35"
                  color={getStatusDoorColor(row.isOpen)}
                  sx={{ p: 1, borderRadius: 1 }}
                >
                  {getStatusDoorLabel(row.isOpen)}
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

// const EmptyTable = ({col}) => {
//   return (
//     <StyledTableRow>
//       <StyledTableCell colSpan={col} align="center">
//         <Empty />
//       </StyledTableCell>
//     </StyledTableRow>
//   )
// }

const Loading = ({ col }) => {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={col} align="center">
        <LinearProgress color="ochre" />
      </StyledTableCell>
    </StyledTableRow>
  );
};
