import { all, call, put, takeEvery } from "redux-saga/effects";

import {
  getCameraDetail,
  getListCameraByAreas,
  updateCameraDetail,
} from "../../services/camera.service";
import alertSlice from "../alert/slice";
import {
  getCameras,
  setCameras,
  updateCamera,
  updateCameraSuccess,
} from "./slice";

function* getCamerasSaga({ payload }) {
  try {
    const response = yield call(getListCameraByAreas, payload);
    if (response.status === 200) {
      const respData = response.data;
      yield put(setCameras(respData?.data?.rows ?? []));
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi tải danh sách camera")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi tải danh sách camera"));
    console.log(error);
  }
}

function* updateCameraSaga({ payload }) {
  try {
    // TODO: remove later, require BE return full data in list
    // Not need to call get detail API
    const respCamDetail = yield call(getCameraDetail, payload.id);
    if (respCamDetail.status === 200) {
      const camDetail = respCamDetail.data.data;
      const response = yield call(updateCameraDetail, {
        ...camDetail,
        ...payload,
      });

      if (response.status === 200) {
        yield put(alertSlice.actions.success("Cập nhật camera thành công"));
        yield put(updateCameraSuccess(payload));
      } else {
        yield put(alertSlice.actions.error("Lỗi xảy ra khi cập nhật camera"));
        console.log("API error");
      }
    } else {
      yield put(alertSlice.actions.error("Lỗi xảy ra khi cập nhật camera"));
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi cập nhật camera"));
    console.log(error);
  }
}

export default function* saga() {
  yield all([
    takeEvery(getCameras().type, getCamerasSaga),
    takeEvery(updateCamera().type, updateCameraSaga),
  ]);
}
