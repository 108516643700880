import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import alertSlice from "../toolkits/alert/slice";
import { generateId, getColorWithSeverity } from "../utils";

const DEFAULT_TOAST_TIMEOUT = 3000;

function useToaster() {
  const dispatch = useDispatch();

  const show = useCallback(
    (payload, timeout = DEFAULT_TOAST_TIMEOUT) => {
      payload.id = generateId();
      dispatch(alertSlice.actions.custom(payload));
      setTimeout(() => dispatch(alertSlice.actions.removeCustom()), timeout);
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      default: (severity, message, timeout = 5000) =>
        show(
          {
            id: "",
            message: message,
            color: "#007bff",
            icon: "",
          },
          timeout
        ),
      warning: (severity, message, timeout = 5000) =>
        show(
          {
            id: "",
            message: message,
            color: getColorWithSeverity(severity),
            icon: "",
          },
          timeout
        ),
    }),
    [show]
  );
}

export default useToaster;
