import { all, call, put, takeEvery } from "redux-saga/effects";

import { getAreaByCode, getListArea } from "../../services/area.service";
import alertSlice from "../alert/slice";
import {
  getArea,
  setArea,
  getListAreas,
  getListAreasSuccess,
  selectArea,
  updateSelectArea,
} from "./slice";

function* getAreaByCodeSaga({ payload }) {
  try {
    const response = yield call(getAreaByCode, payload);
    if (response.status === 200) {
      const respData = response.data;
      yield put(setArea(respData?.data));
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi tải chi tiết khu vực")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi tải chi tiết khu vực"));
    console.log(error);
  }
}

function* getListAreaSaga() {
  try {
    const response = yield call(getListArea);
    if (response.status === 200) {
      const respData = response.data;
      yield put(getListAreasSuccess(respData?.data));
    } else {
      yield put(alertSlice.actions.error("Có lỗi xảy ra"));
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Có lỗi xảy ra"));
    console.log(error);
  }
}

function* getSelectedAreaByCodeSaga({ payload }) {
  if (!payload?.areaCode) {
    return;
  }

  try {
    const response = yield call(getAreaByCode, payload.areaCode);
    if (response.status === 200) {
      const respData = response.data;
      yield put(updateSelectArea({ ...payload, id: respData?.data?.id }));
    } else {
      yield put(
        alertSlice.actions.error("Lỗi xảy ra khi tải chi tiết khu vực")
      );
      console.log("API error");
    }
  } catch (error) {
    yield put(alertSlice.actions.error("Lỗi xảy ra khi tải chi tiết khu vực"));
    console.log(error);
  }
}

export default function* saga() {
  yield all([takeEvery(getArea().type, getAreaByCodeSaga)]);
  yield all([takeEvery(getListAreas().type, getListAreaSaga)]);
  yield all([takeEvery(selectArea().type, getSelectedAreaByCodeSaga)]);
}
