import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #ffffff1f",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#18403c",
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.white,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.STAFF"]: {
    color: "#52C41A",
    fontSize: 14,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.VISITOR"]: {
    color: "#FAAD14",
    fontSize: 14,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.OTHERS"]: {
    color: "#FF4D4F",
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
