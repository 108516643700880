import { useThree } from "@react-three/fiber";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectAnnotation } from "../toolkits/annotation/slice";
import {
  DEFAULT_ANNOTATION_TYPE_TANG,
  buildFloorPathFromFloorTitle,
  changeCameraPosition,
  changeControlsTarget,
} from "../utils";

const useSelectAnnotation = (controls) => {
  const { camera } = useThree();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelect = useCallback(
    (a) => {
      if (a.annotationType === DEFAULT_ANNOTATION_TYPE_TANG) {
        const floorPath = buildFloorPathFromFloorTitle(a.title);
        if (floorPath) navigate(floorPath);
        return;
      }

      changeControlsTarget(controls, a.lookAt);
      changeCameraPosition(camera, a.camPos);
      setTimeout(() => {
        dispatch(selectAnnotation(a));
      }, 800);
    },
    [camera, controls, dispatch, navigate]
  );

  const handleUnSelect = useCallback(() => {
    dispatch(selectAnnotation(null));
  }, [dispatch]);

  return { handleSelect, handleUnSelect };
};

export default useSelectAnnotation;
