import { createSlice } from "@reduxjs/toolkit";

import { replaceitemInList } from "../../utils";

const initialState = {
  errorMassage: false,
  isLoading: false,
  floors: [],
  floorDto: {
    // page: 1,
    // limit: 100,
    // keyword: "",
  },
};

const reducer = createSlice({
  name: "floors",
  initialState,
  reducers: {
    getFloors: (state, action) => {
      state.floors = [];
      state.floorDto = action.payload;
    },
    setFloors: (state, action) => {
      state.floors = action.payload;
    },
    updateFloor: () => {},
    updateFloorSuccess: (state, action) => {
      const floor = action.payload ?? {};
      state.floors = replaceitemInList(state.floors, floor);
    },
  },
});

export const { getFloors, setFloors, updateFloor, updateFloorSuccess } =
  reducer.actions;

export default reducer;
