import { styled } from "@mui/material"
import Box from "@mui/material/Box"

export const AnimatedBorderWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // position: "relative",
    borderTopWidth: 16,
    borderBottomWidth: 16,
    zIndex: 0,
    borderTopColor: "#6D6E75",
    height: 40,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
});

export const SpanAnimatedLeft2Right = styled('span')`
    position: absolute;
    width: 5%;
    height: 2px;
    padding-left: 4px;
    top: -2px;
    left: 0;
    background: linear-gradient(to right, #6D6E75, #34ffea);
    animation: animate1 6s linear infinite;
    @keyframes animate1 {
        0%{
          transform: translateX(-10%);
        }
        100%{
          transform: translateX(2000%);
        }
      }
      
`

export const SpanAnimatedRight2Left = styled('span')`
    position: absolute;
    width: 5%;
    height: 2px;
    padding-right: 4px;
    background: linear-gradient(to left, #6D6E75, #34ffea);
    bottom: -2px;
    right: 0;
    animation: animate2 6s linear infinite;
    @keyframes animate2 {
        0%{
          transform: translateX(10%);
        }
        100%{
          transform: translateX(-2000%);
        }
      }
`