import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  TableContainer,
  Typography,
} from "@mui/material";
import {
  IconShieldCheck,
  IconVolume,
  IconVolumeOff,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { TOPIC_MQTT } from "../../commons/constant";
import EventClassifyButton from "../../components/Button/EventClassifyButton";
import useActiveArea from "../../hooks/useActiveArea";
import useEventStatistic from "../../hooks/useEventStatistic";
import useMqtt from "../../hooks/useMqtt";
import useToaster from "../../hooks/useToaster";
import { client, connectStatus } from "../../services/commonService/mqtt";
import eventSlice from "../../toolkits/event/slice";
import {
  DEFAULT_SEVERITY_CRITICAL,
  DEFAULT_SEVERITY_HIGH,
  DEFAULT_STATUS_NEW,
  DEFAULT_STATUS_PROCESSING,
  getEventTypeLabel,
  getStatusColor,
} from "../../utils";
import ChangeOpenLevel from "./ChangeOpenLevel";
import { GeneralEventType } from "./GeneralEventType";
import { CamAiTables } from "./table/CustomizedTables";

const DEFAULT_INTERVAL_GET_EVENTS = 60 * 1000;

const EventList = () => {
  const toaster = useToaster();
  const dispatch = useDispatch();

  const [connStatus, setConnStatus] = useState(connectStatus);
  const { connect, disconnect, subscribe } = useMqtt();

  const { events, openLevel, muteAudio, camAiFilter } = useSelector(
    (state) => state.events
  );
  const {
    // countCompletedSkip,
    countNew,
    countProcessing,
  } = useEventStatistic(events);

  const { pathname } = useLocation();
  const { selectedArea } = useSelector((state) => state.areas);
  const { areaId } = useActiveArea();
  const isShowTableEvent = Boolean(areaId || pathname === "/");

  useEffect(() => {
    disconnect();
    connect();
  }, [disconnect, connect]);

  useEffect(() => {
    if (connStatus === "Connected") {
      subscribe(TOPIC_MQTT.CAMERA_EVENT);
      subscribe(TOPIC_MQTT.LIDAR_EVENT);
      subscribe(TOPIC_MQTT.FENCE_EVENT);
    }
  }, [subscribe, connStatus]);

  useEffect(() => {
    if (areaId) {
      dispatch(eventSlice.actions.addFilterCamAi({ areaId }));
    } else if (!selectedArea) {
      dispatch(eventSlice.actions.removeFilterCamAI({ areaId: "" }));
    }
  }, [areaId, selectedArea, dispatch]);

  useEffect(() => {
    dispatch(eventSlice.actions.getEventCamAIWithFilter());
  }, [camAiFilter, dispatch]);

  useEffect(() => {
    dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));

    const interval = setInterval(() => {
      dispatch(eventSlice.actions.getEventCamAIWithFilter());
    }, DEFAULT_INTERVAL_GET_EVENTS);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnStatus("Connected");
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        setConnStatus("Reconnecting");
      });
      client.on("message", (topic, message) => {
        console.log(topic);
        if (topic === TOPIC_MQTT.CAMERA_EVENT) {
          const msg = JSON.parse(message);
          if (
            msg.severity === DEFAULT_SEVERITY_HIGH ||
            msg.severity === DEFAULT_SEVERITY_CRITICAL
          ) {
            dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));
            dispatch(eventSlice.actions.addCameraMqtt(msg));
            dispatch(eventSlice.actions.getEventCamAIWithFilter());
            const name = msg.name;
            toaster.warning(
              msg.severity,
              `${name} - ${getEventTypeLabel(msg.eventType)} - ${
                msg.description
              }`
            );
          }
          return;
        }
        
        if (topic === TOPIC_MQTT.LIDAR_EVENT) {
          const msg = JSON.parse(message);
          if (
            msg.severity === DEFAULT_SEVERITY_HIGH ||
            msg.severity === DEFAULT_SEVERITY_CRITICAL
          ) {
            dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));
            dispatch(eventSlice.actions.addLidarMqtt(msg));
            dispatch(eventSlice.actions.getEventCamAIWithFilter());
            const name = msg.name;
            toaster.warning(
              msg.severity,
              `${name} - ${msg.eventType} - ${msg.description}`
            );
          }
          return;
        }
        
        if (topic === TOPIC_MQTT.FENCE_EVENT) {
          const msg = JSON.parse(message);
          if (
            msg.severity === DEFAULT_SEVERITY_HIGH ||
            msg.severity === DEFAULT_SEVERITY_CRITICAL
          ) {
            dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));
            dispatch(eventSlice.actions.addFenceMqtt(msg));
            dispatch(eventSlice.actions.getEventCamAIWithFilter());
            const name = msg.name;
            toaster.warning(
              msg.severity,
              `${name} - ${msg.eventType} - ${msg.description}`
            );
          }
          return;
        }
        
        console.log("Not handle event message", topic, message);
      });
    }
  }, [toaster, dispatch]);

  const changeMuteAudio = () => {
    dispatch(eventSlice.actions.changeMute());
  };

  const filterByStatusEventCamAI = (status) => {
    if (status !== "")
      dispatch(eventSlice.actions.addFilterCamAi({ status: status }));
    else dispatch(eventSlice.actions.removeFilterCamAI({ status: "" }));
  };

  return isShowTableEvent ? (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        backgroundColor: "#18403ccd",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          overflow: "hiden",
        }}
      >
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container item xs={4} justifyContent={"left"}>
            <Box sx={{ display: "flex", alignItems: "center" }} px={1}>
              <IconButton>
                <IconShieldCheck stroke={1.5} size="1rem" color="#fff" />
              </IconButton>
              <Typography color="#fff">Danh sách cảnh báo</Typography>

              <IconButton onClick={changeMuteAudio}>
                {muteAudio ? (
                  <IconVolumeOff stroke={1.5} size="1rem" color="#fff" />
                ) : (
                  <IconVolume stroke={1.5} size="1rem" color="#fff" />
                )}
              </IconButton>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ display: "flex", alignItems: "center" }} px={1}>
              <EventClassifyButton
                color={"#ffffff"}
                btnColor={!camAiFilter?.status ? "warning" : "info"}
                text={`Tất cả`}
                onClick={() => filterByStatusEventCamAI("")}
              />
              <EventClassifyButton
                color={getStatusColor(DEFAULT_STATUS_NEW)}
                btnColor={
                  camAiFilter?.status === DEFAULT_STATUS_NEW
                    ? "warning"
                    : "info"
                }
                text={countNew}
                onClick={() => filterByStatusEventCamAI(DEFAULT_STATUS_NEW)}
              />
              <EventClassifyButton
                color={getStatusColor(DEFAULT_STATUS_PROCESSING)}
                btnColor={
                  camAiFilter?.status === DEFAULT_STATUS_PROCESSING
                    ? "warning"
                    : "info"
                }
                text={countProcessing}
                onClick={() =>
                  filterByStatusEventCamAI(DEFAULT_STATUS_PROCESSING)
                }
              />
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent={"right"}
            alignItems={"center"}
          >
            <GeneralEventType />
            <ChangeOpenLevel />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer
          sx={{
            backgroundColor: "#18403ccd",
            maxHeight: `${openLevel * 10}vh`,
            width: "100%",
          }}
        >
          <CamAiTables />
        </TableContainer>
      </Box>
    </Card>
  ) : null;
};

export default EventList;
