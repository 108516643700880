import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  TablePagination,
  tablePaginationClasses,
} from "@mui/base/TablePagination";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #ffffff1f",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#18403c",
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.white,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.high"]: {
    color: "#FF4D4F",
    fontSize: 14,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.critical"]: {
    color: "#f806cc",
    fontSize: 14,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.NEW"]: {
    color: "#FF4D4F",
    fontSize: 14,
  },
  // eslint-disable-next-line no-useless-computed-key
  ["&.PROCESSING"]: {
    color: "#52C41A",
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${tablePaginationClasses.spacer} {
    display: none;
  }

  & .${tablePaginationClasses.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${tablePaginationClasses.selectLabel} {
    margin: 0;
  }

  & .${tablePaginationClasses.select}{
    padding: 2px;
    color: white;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  & .${tablePaginationClasses.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${tablePaginationClasses.actions} {
    padding: 2px;
    // border: 1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[200]
    };
    border-radius: 50px;
    text-align: center;
  }

  & .${tablePaginationClasses.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    color: white;

    &:hover {
      cursor: pointer;
    }

    // &:focus {
    //   outline: 1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.blue[400]
        : theme.palette.blue[200]
    };
    // }
  }
  `
);
