import createApiService from "./commonService/baseApiService";

// export const AlertCameraMQTT = (data) => {
//   const dispatch = useDispatch();
//   dispatch(eventSlice.actions.getEvent(data));
// };

// const api = createApiService()

const api = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

const apiDevice = createApiService({
  baseURL: process.env.REACT_APP_API_URL,
});


export const getEventList = (pageNumber, pageSize, keyword) => {
  return api.makeRequest({
    url: `events/filter?limit=${pageSize}&page=${pageNumber}&keyword=${keyword}`,
    method: "GET",
  })
}

export const getEventCamAiWithFilter = (pageNumber, pageSize, keyword, filter) => {
  return api.makeRequest({
    url: `events/filter?limit=${pageSize}&page=${pageNumber}&keyword=${keyword}`,
    method: "GET",
    params: filter
  })
}

//updatedStatus: NEW || PROCESSING
export const updateStatusEventCamAI = (payload) => {
  return api.makeRequest({
    url: `events/${payload.eventId}/update`,
    method: "PUT",
    data: {
      updatedStatus: payload.status,
      note: "",
      attachmentFileIds: []
    } //{ "updatedStatus": "COMPLETED_SKIP", "note": "", "attachmentFileIds": []}
  })
}

export const getEventDeviceLogs = (pageSize, pageNumber, options) => {
  return apiDevice.makeRequest({
    url: `device_logs?page=${pageSize}&size=${pageNumber}`, //&orderBy&eventType&eventFrom&eventTo&accessCode&deviceCode`,
    method: "GET",
    params: options
  })
}

export const getEventType = () => {
  return api.makeRequest({
    url: `events/list-event-types`,
    method: "GET",
  });
};
