import { DEFAULT_INVALID_COORDINATE } from "../utils";
import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

export const getListLidar = (params) => {
  return api.makeRequest({
    url: "lidars",
    method: "GET",
    params,
  });
};

export const getLidarDetail = (lidarId) => {
  return api.makeRequest({
    url: `lidars/${lidarId}`,
    method: "GET",
  });
};

// TODO: update later, not build body manually
export const updateLidarDetail = (lidar) => {
  const lidarId = lidar.id;
  const newLidar = {
    areaId: lidar?.areaId ?? "",
    lidarDes: lidar?.description ?? "",
    lidarName: lidar?.lidarName ?? "",
    ipAddress: lidar?.ipAddress ?? "",
    lidarPosX: String(lidar?.lidarPos?.x ?? DEFAULT_INVALID_COORDINATE),
    lidarPosY: String(lidar?.lidarPos?.y ?? DEFAULT_INVALID_COORDINATE),
    lidarPosZ: String(lidar?.lidarPos?.z ?? DEFAULT_INVALID_COORDINATE),
    lidarTitle: lidar?.title ?? "",
    imageUrl: lidar?.imageUrl ?? "",
    libraryId: lidar?.libraryId ?? "",
    lookPosX: String(lidar?.lookAt?.x ?? DEFAULT_INVALID_COORDINATE),
    lookPosY: String(lidar?.lookAt?.y ?? DEFAULT_INVALID_COORDINATE),
    lookPosZ: String(lidar?.lookAt?.z ?? DEFAULT_INVALID_COORDINATE),
    latitude: lidar.latitude,
    longitude: lidar.longitude,
    camera: lidar.cameraInfos?.map((cam) => ({ ...cam, deviceName: cam.name,  })) ?? [],
  };
  return api.makeRequest({
    url: `lidars/${lidarId}`,
    method: "PUT",
    data: newLidar,
  });
};
