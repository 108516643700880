// import FloorIcon from "@mui/icons-material/Apartment";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import LidarIcon from "@mui/icons-material/CompassCalibration";
// import FenceIcon from "@mui/icons-material/Fence";
import {
  IconBuilding,
  IconDeviceCctv,
  IconDevicesCheck,
  IconFence,
  IconLock,
  IconLockCancel,
  IconLockCheck,
  IconRouter,
} from "@tabler/icons-react";
import TWEEN from "@tweenjs/tween.js";
import dayjs from "dayjs";
import { matchPath } from "react-router";

import area_model_mapping from "../commons/area-model-mapping.json";
import fence_mapping from "../commons/fences-mapping.json";
import { toLowerCaseNonAccentVietnamese } from "./vietnameseHelper";

const DEFAULT_START_FLOOR = 0;
const DEFAULT_AMOUNT_FLOOR = 14;
// const DEFAULT_EXCLUDE_FLOORS = ["12"];

export const DEFAULT_OPEN_LEVEL_SM = 1;
export const DEFAULT_OPEN_LEVEL_MD = 2;
export const DEFAULT_OPEN_LEVEL_LG = 5.5;

export const DEFAULT_ANNOTATION_TYPE_ALL = "All"; // Just for show all
export const DEFAULT_ANNOTATION_TYPE_CAMERA = "Camera";
export const DEFAULT_ANNOTATION_TYPE_LIDAR = "Lidar";
export const DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU = "HangRaoDienTu";
export const DEFAULT_ANNOTATION_TYPE_ACCESSCONTROL = "AccessControl";
export const DEFAULT_ANNOTATION_TYPE_TANG = "Tang";

export const DEFAULT_AREA_CODE_HANG_RAO = "Khu-Vuc-Hang-Rao";

export const DEFAULT_EDIT_NONE = "none";
export const DEFAULT_EDIT_ADD = "add";
export const DEFAULT_EDIT_REMOVE = "remove";

export const DEFAULT_STATUS_NEW = "NEW";
export const DEFAULT_STATUS_PROCESSING = "PROCESSING";
export const DEFAULT_STATUS_COMPLETED_SKIP = "COMPLETED_SKIP";
export const DEFAULT_STATUS_INVALID_WARNING = "INVALID_WARNING";
export const DEFAULT_STATUS_COMPLETED_VIOLATION = "COMPLETED_VIOLATION";

export const DEFAULT_VIDEO_WIDTH = 426;
export const DEFAULT_VIDEO_HEIGHT = 240;

export const DEFAULT_SEVERITY_HIGH = "high";
export const DEFAULT_SEVERITY_CRITICAL = "critical";

export const DEFAULT_EVENT_TYPE_HUMAN = "HUMAN";
export const DEFAULT_EVENT_TYPE_CLIMBING = "CLIMBING";
export const DEFAULT_LIDAR_OBJECT_DETECTED = "LIDAR_OBJECT_DETECTED";
export const DEFAULT_FENCE_OBJECT_DETECTED = "FENCE_OBJECT_DETECTED";

export const DEFAULT_PAGE_SIZE = 100;

export const formatFloorNumber = (num) => String(parseInt(num));

export const buildFloorKeys = () => {
  return Array.from({ length: DEFAULT_AMOUNT_FLOOR }, (_, index) => {
    return formatFloorNumber(index + DEFAULT_START_FLOOR);
  });
};

export const buildFloorPath = (formatFloor) => `floor-${formatFloor}`;

export const buildAreaCode = (formatFloor) => `TANG${formatFloor}`;

export const extractFirstNumberFromString = (str) => {
  const match = str.match(/\d+/);
  return match ? match[0] : null;
};

export const buildFloorPathFromFloorTitle = (title) => {
  const floorNumber = extractFirstNumberFromString(title);
  if (!floorNumber) return null;

  const floorKeys = buildFloorKeys();
  const formatFloor = formatFloorNumber(floorNumber);
  if (!floorKeys.includes(formatFloor)) return null;

  return buildFloorPath(formatFloor);
};

export const getActive = (path, pathname, matchEnd = false) => {
  return path ? !!matchPath({ path, end: matchEnd }, pathname) : false;
};

export const hasAdmin = (role) => {
  return Boolean(role?.find((it) => String(it).toUpperCase() === "ADMIN"));
};

export const hasSecurityDepartment = (role) => {
  return Boolean(
    role?.find((it) => String(it).toUpperCase() === "SECURITY_DEPARTMENT")
  );
};

export const DEFAULT_INVALID_COORDINATE = 2000000000;

export const validNumber = (a) =>
  typeof a === "number" && a < DEFAULT_INVALID_COORDINATE;

export const validPosition = (obj) =>
  obj && validNumber(obj.x) && validNumber(obj.y) && validNumber(obj.z);

export const hasPosition = (obj) =>
  Boolean(validPosition(obj?.camPos) && validPosition(obj?.lookAt));

export const buildInvalidPosition = () => ({
  x: DEFAULT_INVALID_COORDINATE,
  y: DEFAULT_INVALID_COORDINATE,
  z: DEFAULT_INVALID_COORDINATE,
});

export const replaceitemInList = (list, item) => {
  return list.map((it) => {
    if (it.id === item.id) {
      return { ...item };
    }
    return it;
  });
};

export const isRightClickOnModel = (event) => event?.nativeEvent?.button === 2;

export const passedTimestamp = (date) => {
  const timestamp = date.getTime() / 1000000;
  const now = Math.floor(Date.now() / 1000);
  return now - timestamp;
};

export const date2Timestamp = (date) => {
  return new Date(date).getTime() * 1000;
};

export const addOneDay = (date) => {
  let toDate = new Date(date);
  toDate.setDate(toDate.getDate() + 1);
  return toDate;
};

export const date2passtime = (date) => {
  if (date === 0) return `Vài giây trước`;
  const timestamp = parseInt(date / 1000000);
  const now = Math.floor(Date.now() / 1000);
  const delta = now - timestamp < 0 ? 30 : now - timestamp;

  const day = Math.floor(delta / (24 * 3600));
  const hour = Math.floor(delta / 3600) % 24;
  const min = Math.floor(delta / 60) % 60;
  const sec = delta % 60;
  if (day) {
    return `${day} ngày trước`;
  } else if (hour) {
    return `${hour} giờ trước`;
  } else if (min) {
    return `${min} phút trước`;
  } else if (sec) {
    return `Vài giây trước`;
  }
};

export const changeControlsTarget = (
  controls,
  position,
  duration = 1000,
  easingFunc = TWEEN.Easing.Cubic.Out
) => {
  new TWEEN.Tween(controls.current.target)
    .to(
      {
        x: position.x,
        y: position.y,
        z: position.z,
      },
      duration
    )
    .easing(easingFunc)
    .start();
};

export const changeControlsPosition = (
  controls,
  position,
  duration = 1000,
  easingFunc = TWEEN.Easing.Cubic.Out
) => {
  new TWEEN.Tween(controls.current.position)
    .to(
      {
        x: position.x,
        y: position.y,
        z: position.z,
      },
      duration
    )
    .easing(easingFunc)
    .start();
};

export const changeCameraPosition = (
  camera,
  position,
  duration = 1000,
  easingFunc = TWEEN.Easing.Cubic.Out
) => {
  new TWEEN.Tween(camera.position)
    .to(
      {
        x: position.x,
        y: position.y,
        z: position.z,
      },
      duration
    )
    .easing(easingFunc)
    .start();
};

export const generateId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2, 5);

export const getColorWithSeverity = (severity) => {
  if (severity === DEFAULT_SEVERITY_HIGH) return "#FF4D4F";
  if (severity === DEFAULT_SEVERITY_CRITICAL) return "#ed4dd0";
  return "#ffffff";
};

export const getStatusColor = (status) => {
  if (status === DEFAULT_STATUS_NEW) return "#ed4dd0";
  if (status === DEFAULT_STATUS_COMPLETED_VIOLATION) return "#52C41A";
  if (status === DEFAULT_STATUS_PROCESSING) return "#FF4D4F";
  if (status === DEFAULT_STATUS_COMPLETED_SKIP) return "#ffffff";
  if (status === DEFAULT_STATUS_INVALID_WARNING) return "#";
  return "transparent";
};

export const getStatusLabel = (status) => {
  if (status === DEFAULT_STATUS_NEW) return "Chưa xử lí";
  if (status === DEFAULT_STATUS_PROCESSING) return "Đang xử lý";
  if (status === DEFAULT_STATUS_COMPLETED_SKIP) return "Bỏ qua/Đã xem";
  if (status === DEFAULT_STATUS_INVALID_WARNING) return "Không xác định";
  if (status === DEFAULT_STATUS_COMPLETED_VIOLATION) return "Đã xử lí";
  return status;
};

export const getStatusActionIcon = (status) => {
  if (status === DEFAULT_STATUS_PROCESSING)
    return <IconLock stroke={1.5} size="1rem" color="#ffff" />;

  if (status === DEFAULT_STATUS_COMPLETED_SKIP)
    return <IconLockCheck stroke={1.5} size="1rem" color="#ffff" />;

  if (status === DEFAULT_STATUS_COMPLETED_VIOLATION)
    return <IconLockCancel stroke={1.5} size="1rem" color="#ffff" />;

  return null;
};

export const getEventTypeLabel = (eventType) => {
  if (eventType === DEFAULT_EVENT_TYPE_HUMAN)
    return "Phát hiện người lảng vảng";
  if (eventType === DEFAULT_EVENT_TYPE_CLIMBING)
    return "Phát hiện người trèo tường";
  if (eventType === DEFAULT_LIDAR_OBJECT_DETECTED) return "Thiết bị Lidar";
  if (eventType === DEFAULT_FENCE_OBJECT_DETECTED) return "Hàng rào điện tử";
  return eventType;
};

export const getUserTypeLabel = (userType) => {
  if (userType === "STAFF") return "Nhân viên";
  if (userType === "VISITOR") return "Khách";
  return userType;
};

export const drawBlackScreenToCanvas = (canvasId) => {
  const canvas = document.getElementById(canvasId);
  const ctx = canvas.getContext("2d");
  const background = new Image();
  background.src =
    "http://macmyths.com/wp-content/uploads/2019/04/macbook-camera-not-working.jpg";
  ctx.drawImage(background, 0, 0);
};

export const checkEnableStream = (status, camUrl) => {
  // TODO: remove later - test public rtsp
  const isPublicRtsp = camUrl?.includes("@113.");
  return status === "online" || isPublicRtsp;
};

export const fuzzySearch = (input, pattern) => {
  let inputIndex = 0;
  let patternIndex = 0;

  while (inputIndex < input.length && patternIndex < pattern.length) {
    if (input[inputIndex] === pattern[patternIndex]) {
      patternIndex++;
    }
    inputIndex++;
  }

  return patternIndex === pattern.length;
};

export const filterTreeByKeyword = (treeData, keyword) => {
  return treeData?.reduce((result, item) => {
    const children = filterTreeByKeyword(item.children, keyword);

    const matchesKeyword = fuzzySearch(
      toLowerCaseNonAccentVietnamese(item.title ?? ""),
      toLowerCaseNonAccentVietnamese(keyword)
    );

    if (matchesKeyword || children?.length > 0) {
      const filteredItem = { ...item };
      if (children?.length > 0) {
        filteredItem.children = children;
      }
      if (children) {
        filteredItem.title = `${filteredItem.title} (${children.length})`;
      }
      result.push(filteredItem);
    }

    return result;
  }, []);
};

export const buildWithElectron = () => Boolean(window?.electronAPI);

export const isExternalLink = (link) => link.startsWith("http");

export const safeOpenExternalLink = (link) =>
  window.open(link, "_blank", "noopener noreferrer");

export const openExternalLinkInSameTab = (link) => {
  window.location.href = link;
};

export const navigateOrOpenExternalLink = (link, navigate) => {
  if (isExternalLink(link)) {
    safeOpenExternalLink(link);
  } else {
    navigate(link);
  }
};

export const getIconForAnnotation = (annotationType) => {
  if (annotationType === DEFAULT_ANNOTATION_TYPE_CAMERA) return IconDeviceCctv;
  if (annotationType === DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU)
    return IconFence;
  if (annotationType === DEFAULT_ANNOTATION_TYPE_LIDAR) return IconRouter;
  if (annotationType === DEFAULT_ANNOTATION_TYPE_TANG) return IconBuilding;
  if (annotationType === DEFAULT_ANNOTATION_TYPE_ACCESSCONTROL)
    return IconDevicesCheck;
  return null;
};

export const checkAnnotationHasEmergency = (
  annotation,
  emergencyEvents = []
) => {
  if (annotation.annotationType === DEFAULT_ANNOTATION_TYPE_TANG) {
    const _annotationTitle = formatFloorNumber(
      extractFirstNumberFromString(annotation.floorName)
    );

    return Boolean(
      emergencyEvents.find(
        (event) =>
          _annotationTitle ===
          formatFloorNumber(extractFirstNumberFromString(event.areaName))
      )
    );
  }

  if (annotation.annotationType === DEFAULT_ANNOTATION_TYPE_CAMERA) {
    return Boolean(
      emergencyEvents.find(
        (event) => event.deviceName === annotation.deviceName
      )
    );
  }

  if (annotation.annotationType === DEFAULT_ANNOTATION_TYPE_LIDAR) {
    return Boolean(
      emergencyEvents.find((event) => event.lidarName === annotation.lidarName)
    );
  }

  if (annotation.annotationType === DEFAULT_ANNOTATION_TYPE_HANGRAODIENTU) {
    return Boolean(
      emergencyEvents.find((event) => event.fenceName === annotation.fenceName)
    );
  }

  return false;
};

export const checkAreaHasEmergency = (name, emergencyEvents) => {
  return emergencyEvents.find((it) => name.includes(it.areaName));
};

export const checkFenceHasEmergency = (name, emergencyEvents) => {
  return emergencyEvents.find((it) => name.includes(it.fenceName));
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc" // or asc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilized = array.map((el, index) => [el, index]);

  stabilized.sort((value1, value2) => {
    const order = comparator(value1[0], value2[0]);
    if (order !== 0) {
      return order;
    }
    return value1[1] - value2[1];
  });
  return stabilized.map((el) => el[0]);
};

export const timestamp2datetime = (timestampe) => {
  const date = new Date(timestampe / 1000);
  return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
};

export const parseRawMsg = (rawMsg) => {
  if (!rawMsg) return null;

  try {
    return JSON.parse(rawMsg);
  } catch (error) {
    return null;
  }
};

export const normalizationLink = (link) => {
  if (typeof link !== "string") return "";
  if (link.startsWith("http")) return link;
  return `http://${link}`;
};

export const fixScenceName = (sceneName, pathname) => {
  // TODO: remove the magic logic if the model change
  const floorNumber = parseInt(pathname.replace("/floor-", ""));
  const finalSceneName =
    floorNumber >= 5 && floorNumber <= 10
      ? sceneName.replace(4, floorNumber)
      : sceneName;
  return finalSceneName;
};

export const filterAreasInModel = (areaCode) => {
  return area_model_mapping.filter((it) => it.areaCode === areaCode);
};

export const mappingArea2Model = (sceneName, pathname) => {
  const finalSceneName = fixScenceName(sceneName, pathname);
  return area_model_mapping.find((area) => area.model === finalSceneName);
};

export const mappingFences2Model = (sceneName) => {
  return fence_mapping.find((fence) => fence.model === sceneName);
};

export const checkVisibleArea = (sceneName) => {
  let visible = false;
  area_model_mapping.forEach((area) => {
    if (area.model === sceneName) visible = true;
  });
  return visible;
};

export const checkVisibleFence = (sceneName) => {
  let visible = false;
  fence_mapping.forEach((fence) => {
    if (fence.model === sceneName) visible = true;
  });
  return visible;
};

export const isLidarEvent = (event) => event?.eventType?.includes("LIDAR");

export const isFenceEvent = (event) => event?.eventType?.includes("FENCE");

export const toRadians = (angle) => (angle * Math.PI) / 180;

export const haversineDistance = (lat1, lon1, lat2, lon2) => {
  // Radius of the Earth in kilometers
  const R = 6371.0;

  // Convert latitude and longitude from degrees to radians
  lat1 = toRadians(lat1);
  lon1 = toRadians(lon1);
  lat2 = toRadians(lat2);
  lon2 = toRadians(lon2);

  // Calculate the differences in coordinates
  const dlat = lat2 - lat1;
  const dlon = lon2 - lon1;

  // Haversine formula
  const a =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance
  const distance = R * c;

  return distance;
};

export const findNearestFence = (lat, long, fences) => {
  let distance = -1;
  let result = null;

  for (const fence of fences) {
    const d = haversineDistance(lat, long, fence.latitude, fence.longitude);
    if (distance < 0 || d < distance) {
      distance = d;
      result = fence;
    }
  }

  return result;
};

export const mapLidarEventWithNearestFence = (event, fences = []) => {
  const foundFence = findNearestFence(event.objLat, event.objLong, fences);
  return foundFence
    ? {
        ...event,
        fenceName: foundFence?.fenceName,
        nearestFence: foundFence,
      }
    : event;
};

export const getStatusDoorColor = (status) => {
  if (status) return "#ed4dd0";
  return "#52C41A";
};

export const getStatusDoorLabel = (status) => {
  if (status) return "Cửa mở";
  return "Cửa đóng";
};

export const getStatusRelayDoorLabel = (status) => {
  if (status) return "Mở khóa";
  return "Đóng khóa";
};

export const findDevice = (code, devices) => {
  return devices.filter((it) => it.code === code)
}

// export const findDoor
