import { Box } from "@mui/material";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import AnnotationDialog from "../components/Annotations/AnnotationDialog";
import DialogSelectAnnotation from "../components/DialogSelectAnnotation";
import Editor from "../components/Editor";
import ToggleShowLabel from "../components/ToggleShowLabel";
import ToggleShowLabelFloor from "../components/ToggleShowLabelFloor";
import AnnotationList from "../screens/annotations/AnnotationList";
import EventList from "../screens/events/EventList";
import { hasSecurityDepartment } from "../utils";
import LayoutContext from "./LayoutContext";
import Navbar from "./navbar";
import CardAreaStatus from "../components/Card/CardAreaStatus";
import SearchPeople from "../components/SearchPeople";
// import AssetDetailDialog from "../components/AssetDetailDialog";

const Layouts = () => {
  const controlsRef = useRef(null);
  const { pathname } = useLocation();

  const { role } = useSelector((state) => state.auth);
  const allowEdit =
    hasSecurityDepartment(role) &&
    (pathname === "/" || pathname.startsWith("/floor-"));

  const has3dModel = pathname === "/" || pathname.startsWith("/floor-");
  const homeModel = pathname === "/";
  const floorModel = pathname.startsWith("/floor-");

  return (
    <LayoutContext.Provider
      value={{
        controlsRef,
      }}
    >
      <Box
        sx={{
          height: "100%",
        }}
      >
        <Navbar />
        <Outlet />

        {homeModel ? (
          <Box
            sx={{
              position: "absolute",
              width: "calc(100% - 5px)",
              top: "10%",
              left: 5,
              pointerEvents: "none",
            }}
          >
            <ToggleShowLabel />
            <AnnotationList />
          </Box>
        ) : floorModel ? (
          <Box
            sx={{
              position: "absolute",
              width: "calc(100% - 5px)",
              top: "13%",
              left: 5,
              pointerEvents: "none",
            }}
          >
            <ToggleShowLabelFloor />
          </Box>
        ) : null}

        {has3dModel ? (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              bottom: "0%",
            }}
          >
            <EventList />
          </Box>
        ) : null}

        <Box
          sx={{
            position: "absolute",
            minWidth: "90px",
            top: "10%",
            right: 5,
          }}
        >
          {allowEdit && (
            <>
              <Editor />
              <DialogSelectAnnotation />
            </>
          )}
          {floorModel && <CardAreaStatus />}
          {homeModel && <SearchPeople />}
        </Box>

        {/* <AssetDetailDialog/> */}
        <AnnotationDialog />
      </Box>
    </LayoutContext.Provider>
  );
};

export default Layouts;
