import React from "react"
import { FormControl, MenuItem, OutlinedInput, Select, Checkbox, ListItemText, Typography } from "@mui/material"


const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const SelectItem = ({ itemName, data, selectedItems, onSelect }) => {

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let items = []

        value.map((v) =>  items.push(v))
        onSelect(items)
    }

    return (
        <div>
            <FormControl sx={{ backgroundColor: "transparent", px: 1 }} size="small" component={"button"}>
                <Select
                    id="demo-multiple-chip"
                    multiple
                    autoWidth
                    displayEmpty
                    IconComponent={() => null}
                    color="ochre"
                    SelectDisplayProps={{ style: { padding: "2px 10px", width: "100%", height: 20, marginTop: 8} }}
                    sx={{ backgroundColor: "#18403ccd"}}
                    value={selectedItems}
                    onChange={handleChange}
                    input={<OutlinedInput/>}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <Typography color="#ffff" fontSize={12}>{itemName}</Typography>;
                        }
                        return <Typography color="#ffff" fontSize={12}>{`${selected.length} ${itemName}`}</Typography>

                    }}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value="">
                        <em>{itemName}</em>
                    </MenuItem>
                    {data?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={selectedItems.indexOf(item.id) > -1} />
                            <ListItemText primary={item.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectItem