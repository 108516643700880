import { Environment, OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useContext } from "react";

import Annotations from "../../components/Annotations";
import { Model } from "../../components/Model/BuildingT1";
// import ThreeLoader from "../../components/ThreeLoader";
import EnvSunSet from "../../assets/venice_sunset_1k.hdr";
import TweenUpdate from "../../components/TweenUpdate";
import usePrepareAreaData from "../../hooks/usePrepareAreaData";

import LayoutContext from "../../layouts/LayoutContext";

export default function Home({ areaCode }) {
  const { controlsRef } = useContext(LayoutContext);

  usePrepareAreaData({ areaCode });

  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      camera={{ fov: 50, zoom: 4.5, position: [-800, 0, 1350] }}
      style={{ zIndex: 0 }}
    >
      <OrbitControls
        ref={controlsRef}
        // target={[0,0,0]}
        // autoRotate
        // minAzimuthAngle={-Math.PI / 4}
        // maxAzimuthAngle={Math.PI / 4}
        minPolarAngle={0}
        maxPolarAngle={Math.PI - Math.PI / 2}
      />
      <Suspense fallback={null}>
        <Environment files={EnvSunSet} background blur={0.65} />
          <Stage
            controls={controlsRef}
            environment={{ files: EnvSunSet }}
            intensity={1}
            shadows={false}
          >
            <Model controls={controlsRef} />
          </Stage>
        <Annotations controls={controlsRef} />
        <TweenUpdate />
      </Suspense>
    </Canvas>
  );
}
