import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";

function DateTime() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000);
  }, []);

  return (
    <Box sx={{ p: "0px 8px", minWidth: 140 }}>
      {dayjs(time).format("DD/MM/YYYY HH:mm:ss")}
    </Box>
  );
}

export default DateTime;
