import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_EDIT_NONE } from "../../utils";

const initialState = {
  selectedAnnotation: null,
  focusedAnnotation: null,
  selectedPosition: null,
  editMode: DEFAULT_EDIT_NONE,
  needSave: false,
  optionShowLabel: null,
};

const reducer = createSlice({
  name: "annotations",
  initialState,
  reducers: {
    selectAnnotation: (state, action) => {
      state.selectedAnnotation = action.payload;
    },
    focusAnnotation: (state, action) => {
      state.focusedAnnotation = action.payload;
    },
    selectPosition: (state, action) => {
      state.selectedPosition = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setNeedSave: (state, action) => {
      state.needSave = action.payload;
    },
    setOptionShowLabel: (state, action) => {
      state.optionShowLabel = action.payload;
    },
  },
});

export const {
  selectAnnotation,
  focusAnnotation,
  selectPosition,
  setEditMode,
  setNeedSave,
  setOptionShowLabel,
} = reducer.actions;

export default reducer;
