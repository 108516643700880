import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getArea } from "../toolkits/area/slice";
import { getCameras, setCameras } from "../toolkits/camera/slice";
import { getDevices } from "../toolkits/devices/slice";
import { getFences, setFences } from "../toolkits/fence/slice";
import { getFloors, setFloors } from "../toolkits/floor/slice";
import { getLidars, setLidars } from "../toolkits/lidar/slice";
import { DEFAULT_AREA_CODE_HANG_RAO } from "../utils";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 199;

const usePrepareAreaData = ({ areaCode }) => {
  const dispatch = useDispatch();
  const { areaDetail } = useSelector((state) => state.areas);

  useEffect(() => {
    dispatch(getArea(areaCode));

    if (areaCode === DEFAULT_AREA_CODE_HANG_RAO) {
      dispatch(
        getLidars({
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          keyword: "",
        })
      );
      dispatch(
        getFences({
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          keyword: "",
        })
      );
      dispatch(
        getFloors({
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          keyword: "",
        })
      );
    } else {
      dispatch(setLidars([]));
      dispatch(setFences([]));
      dispatch(setFloors([]));
    }
  }, [dispatch, areaCode]);

  useEffect(() => {
    if (areaDetail?.id) {
      dispatch(
        getCameras({
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          keyword: "",
          areaId: areaDetail?.id,
        })
      );
      dispatch(getDevices());
    } else {
      dispatch(setCameras([]));
    }
  }, [areaDetail, dispatch]);
};

export default usePrepareAreaData;
