import { DEFAULT_INVALID_COORDINATE } from "../utils";
import createApiService from "./commonService/baseApiService";

const api = createApiService({
  baseURL: process.env.REACT_APP_CAMAI_API_URL,
});

export const getListCameraByAreas = (params) => {
  return api.makeRequest({
    url: "devices/filter",
    method: "GET",
    params,
  });
};

export const getCameraDetail = (cameraId) => {
  return api.makeRequest({
    url: `devices/${cameraId}`,
    method: "GET",
  });
};

// TODO: update later, not build body manually
export const updateCameraDetail = (camera) => {
  const cameraId = camera.id;
  const newCamera = {
    // ...camera,
    // id: undefined,
    // name: camera?.deviceName ?? "",
    // camDes: camera?.description ?? "",
    // camTitle: camera?.title ?? "",
    // camPosX: String(camera?.camPos?.x ?? DEFAULT_INVALID_COORDINATE),
    // camPosY: String(camera?.camPos?.y ?? DEFAULT_INVALID_COORDINATE),
    // camPosZ: String(camera?.camPos?.z ?? DEFAULT_INVALID_COORDINATE),
    // lookPosX: String(camera?.lookAt?.x ?? DEFAULT_INVALID_COORDINATE),
    // lookPosY: String(camera?.lookAt?.y ?? DEFAULT_INVALID_COORDINATE),
    // lookPosZ: String(camera?.lookAt?.z ?? DEFAULT_INVALID_COORDINATE),
    // sirenIds: camera?.sirens?.map((it) => it.id ?? it),
    areaId: camera?.areaId ?? "",
    areaName: camera?.areaName ?? "",
    camDes: camera?.description ?? "",
    camPosX: String(camera?.camPos?.x ?? DEFAULT_INVALID_COORDINATE),
    camPosY: String(camera?.camPos?.y ?? DEFAULT_INVALID_COORDINATE),
    camPosZ: String(camera?.camPos?.z ?? DEFAULT_INVALID_COORDINATE),
    camTitle: camera?.title ?? "",
    camUrl: camera?.camUrl ?? "",
    ip: camera?.ip ?? "",
    lookPosX: String(camera?.lookAt?.x ?? DEFAULT_INVALID_COORDINATE),
    lookPosY: String(camera?.lookAt?.y ?? DEFAULT_INVALID_COORDINATE),
    lookPosZ: String(camera?.lookAt?.z ?? DEFAULT_INVALID_COORDINATE),
    macAddress: camera?.macAddress ?? "",
    maxStreams: camera?.maxStreams ?? 0,
    name: camera?.deviceName ?? "",
    onvifPassword: camera?.onvifPassword ?? "",
    onvifPort: camera?.onvifPort,
    onvifUsername: camera?.onvifUsername ?? "",
    password: camera?.password ?? "",
    port: camera?.port,
    username: camera?.username ?? "",
    sirenIds: camera?.sirens?.map((it) => it.id ?? it),
  };
  return api.makeRequest({
    url: `devices/${cameraId}`,
    method: "PUT",
    data: newCamera,
  });
};
