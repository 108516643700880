import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPosition, selectAnnotation } from "../toolkits/annotation/slice";
import { DEFAULT_EDIT_ADD, changeCameraPosition, changeControlsTarget } from "../utils";

const useModelHandler = (controls) => {
  const dispatch = useDispatch();
  const { editMode } = useSelector((state) => state.annotations);

  const onPointerUp = useCallback(
    (e) => {
      if (editMode === DEFAULT_EDIT_ADD) {
        e.stopPropagation();
        dispatch(
          selectPosition({
            camPos: { ...e.camera.position },
            lookAt: { ...e.point },
          })
        );
      }
    },
    [dispatch, editMode]
  );

  const onDoubleClickHome = useCallback(
    (e) => {
        e.stopPropagation();
        changeCameraPosition(e.camera, {x: -800, y: 0, z: 1550})
        changeControlsTarget(controls, {x: 0, y: 0, z: 0} )
        dispatch(
          selectAnnotation(null)
        );
    },
    [dispatch, controls]
  )

  const onDoubleClickFloor = useCallback(
    (e) => {
        e.stopPropagation();
        changeCameraPosition(e.camera, {x: -14, y: 1970, z: 1866})
        changeControlsTarget(controls, {x: 0, y: 0, z: 0} )
        dispatch(
          selectAnnotation(null)
        );
    },
    [dispatch, controls]
  )

  return {
    onPointerUp,
    onDoubleClickHome,
    onDoubleClickFloor
  };
};

export default useModelHandler;
